// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 获取系统广播 GET /api/msg-center/broadcasts */
export async function msgCenterBroadcastsGet(options?: { [key: string]: any }) {
  return request<API.WebResultListBroadcastMsgVo>('/api/msg-center/broadcasts', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 标记某人在团队所有消息为已读 PUT /api/msg-center/markAllHasRead */
export async function msgCenterMarkAllHasReadPut(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/msg-center/markAllHasRead', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** 标记某些消息为已读。ids必须为对应团队的消息，不可以跨团队 PUT /api/msg-center/markHasRead */
export async function msgCenterMarkHasReadPut(
  params: {
    // query
    /** ids */
    ids: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/msg-center/markHasRead', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取消息列表 GET /api/msg-center/messages */
export async function msgCenterMessagesGet(
  params: {
    // query
    /** pageNum */
    pageNum: number;
    /** pageSize */
    pageSize: number;
    /** messageZoo */
    messageZoo?: 'Activity' | 'Audit' | 'Crs' | 'General' | 'Ips' | 'Payment' | 'Shops' | 'Team';
    /** messageType */
    messageType?: string;
    /** 是否只获取未读消息 */
    unread?: boolean;
    /** 粒度目前是做到天，时分秒会被抹掉 */
    from?: string;
    /** to */
    to?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultUserMessageVo>('/api/msg-center/messages', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户消息订阅配置 GET /api/msg-center/subscribes */
export async function msgCenterSubscribesGet(options?: { [key: string]: any }) {
  return request<API.WebResultListMessageSubscribeVo>('/api/msg-center/subscribes', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 配置用户消息订阅 POST /api/msg-center/subscribes */
export async function msgCenterSubscribesPost(
  body: API.UpdateUserSubscribeRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultListMessageSubscribeVo>('/api/msg-center/subscribes', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取未读消息数量 GET /api/msg-center/unreadCount */
export async function msgCenterUnreadCountGet(
  params: {
    // query
    /** 不为空表示获取指定团队的，为空表示获取所有团队的 */
    targetTeamId?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListUserUnreadMsgCountVo>('/api/msg-center/unreadCount', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
