// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 获取域名白名单 GET /api/meta/dwl */
export async function metaDwlGet(
  params: {
    // query
    /** scope */
    scope?: 'Global' | 'Team' | 'UserIp';
    /** ipId */
    ipId?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListDomainWhitelistItem>('/api/meta/dwl', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询域名白名单申请 GET /api/meta/dwl/audit */
export async function metaDwlAuditGet(
  params: {
    // query
    /** audited */
    audited?: boolean;
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultDwlAuditVo>('/api/meta/dwl/audit', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 发起域名白名单申请 POST /api/meta/dwl/audit */
export async function metaDwlAuditPost(
  params: {
    // query
    /** domain */
    domain: string;
    /** reason */
    reason: string;
    /** userName */
    userName: string;
    /** phone */
    phone: string;
    /** company */
    company?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultDwlAuditDto>('/api/meta/dwl/audit', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询某个域名白名单申请 GET /api/meta/dwl/findDwlAudit/${param0} */
export async function metaDwlFindDwlAuditByAuditIdGet(
  params: {
    // path
    /** auditId */
    auditId: number;
  },
  options?: { [key: string]: any },
) {
  const { auditId: param0, ...queryParams } = params;
  return request<API.WebResultDwlAuditDto>(`/api/meta/dwl/findDwlAudit/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
