import I18N from '@/i18n';
import type { HTMLAttributes, ReactNode } from 'react';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Avatar as AntdAvatar, Typography } from 'antd';
import classNames from 'classnames';
import constants from '@/constants';
import type { AvatarProps } from 'antd/lib/avatar/avatar';
import { getApiUrl, getPortalUrl } from '@/utils/utils';
import styles from './UserAvatarAndName.less';
import { userByUserIdGet } from '@/services/api-Account/UserController';
import Placeholder from './Placeholder';
import defaultAvatar from './images/defaultAvatar.jpg';
import useCurrentUser from '@/hooks/useCurrentUser';

interface Props {
  user: {
    id?: number | string;
    avatar?: string;
    nickname?: string;
  };
  showId?: boolean;
  avatarSize?: number;
  avatarProps?: AvatarProps;
  // 是否禁用：当前用户显示为“我”
  disableMeLabel?: boolean;
  hideLabel?: boolean;
}

/**
 * 头像，处理图片加载失败的情况，显示默认头像
 * @param props
 * @constructor
 */
export function Avatar(props: { userId?: number | string; border?: boolean } & AvatarProps) {
  const { src, userId, border, ...otherProps } = props;
  const [isDefaultAvatar, toggle] = useState(true);
  const [avatar, setAvatar] = useState<ReactNode>('');
  const mounted = useRef(false);

  const loadAvatar = (url: ReactNode) => {
    if (typeof url === 'string') {
      let _url = url;
      if (!/^https?:\/\//.test(url)) {
        // 默认头像门户静态资源
        if (constants.defaultAvatar === url && getPortalUrl()) {
          _url = getPortalUrl(url);
        } else if (getApiUrl()) {
          _url = getApiUrl(url);
        }
      }
      const img = new Image();
      img.onload = () => {
        if (mounted.current) {
          setAvatar(_url);
          toggle(false);
        }
      };
      img.src = _url;
    }
  };
  const update = useCallback(() => {
    if (src) {
      // 优先使用 user.avatar
      loadAvatar(src);
    } else if (userId) {
      // 如果没有图像，尝试通过 userId 去获取用户头像
      const avatarUrl = `/api/account/${userId}/avatar?t=${Date.now()}`;
      loadAvatar(avatarUrl);
    }
  }, [src, userId]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (mounted.current) {
      update();
    }
  }, [update]);
  return (
    <AntdAvatar
      className={classNames({
        [styles.border]: !isDefaultAvatar && border,
      })}
      src={avatar || defaultAvatar}
      {...otherProps}
    />
  );
}

/**
 * 用户头像、昵称和ID
 * 头像优先使用 user.avatar，如果没有传 avatar ，就使用 user.id 去获取
 * @param props
 * @constructor
 */
const UserAvatarAndName: React.FC<Props & HTMLAttributes<any>> = (props) => {
  const {
    user,
    showId,
    avatarSize = 24,
    avatarProps,
    className,
    disableMeLabel = false,
    hideLabel = false,
    ...otherProps
  } = props;
  let { nickname } = user || {};
  const { user: currentUser } = useCurrentUser();
  if (user?.id === 0) {
    nickname = I18N.t('系统');
  }

  if (!disableMeLabel && user?.id === currentUser?.id) {
    nickname = <Typography.Text type={'success'}>{I18N.t('我')}</Typography.Text>;
  }

  return (
    <span className={classNames(styles.userAvatarAndName, className)} {...otherProps}>
      <Avatar
        size={avatarSize}
        src={user?.avatar}
        userId={user?.id}
        style={{ flexShrink: 0 }}
        {...avatarProps}
      />

      {!hideLabel && <span className="nickname">{nickname}</span>}
      {showId && user?.id && (
        <>
          <span className="user-id" title={`ID：${user.id}`}>
            （
          </span>
          <span className="user-id user-id-value" title={`ID：${user.id}`}>
            {user.id}
          </span>
          <span className="user-id" title={`ID：${user.id}`}>
            ）
          </span>
        </>
      )}
    </span>
  );
};

const userRequestCache: Record<string | number, Promise<any>> = {};
export function useUserCacheGet() {
  return useCallback(async (id: number) => {
    if (!Number.isNaN(Number(id))) {
      if (!userRequestCache[id]) {
        userRequestCache[id] = userByUserIdGet(
          { userId: id },
          {
            errorHandler(err: any) {
              console.error(err);
            },
          },
        );
      }
      return userRequestCache[id].then((res) => {
        if (res?.data) {
          return res.data;
        }
      });
    }
  }, []);
}
export function useUserCache(id: number) {
  const [user, setUser] = useState<API.UserVo>();
  const getUser = useUserCacheGet();
  useEffect(() => {
    getUser(id).then((res) => {
      setUser(res);
    });
  }, [getUser, id]);
  return user;
}

interface UserProps {
  id: number;
  disableMeLabel?: boolean;
}

export const User = (props: UserProps & Omit<HTMLAttributes<any>, 'id'>) => {
  const { id, ...otherProps } = props;
  const user = useUserCache(id);
  if (user) {
    return <UserAvatarAndName user={user} {...otherProps} />;
  }
  return <Placeholder />;
};

export default UserAvatarAndName;
