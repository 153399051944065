import I18N from '@/i18n';

export const OrderTypeEnum = {
  BuyIp: I18N.t('购买IP'),
  RenewIp: I18N.t('IP续费'),
  RechargeCredit: I18N.t('购买花瓣'),
  BuyRpaVoucher: I18N.t('购买流程任务卡'),
  RenewRpaVoucher: I18N.t('流程任务卡续费'),
  Recharge: I18N.t('充值'),
  MakeupPriceDifference: I18N.t('补差价'),
  BuyTkPack: I18N.t('购买TK套餐'),
  RenewTkPack: I18N.t('TK套餐续费'),
  // CashOut: { text: t('提现') },
  // Refund: { text: t('退款') },
};
export const PayTypeEnum = {
  AliPay: { text: I18N.t('支付宝') },
  BalancePay: { text: I18N.t('余额支付') },
  BankPay: { text: I18N.t('银行转账') },
  WechatPay: { text: I18N.t('微信支付') },
};
export const PayStatusEnum = {
  Created: { text: I18N.t('已创建') },
  Locked: { text: I18N.t('已锁定') },
  WAIT_CONFIRM: { text: I18N.t('待确认') },
  PAID: { text: I18N.t('已支付') },
  CANCELED: { text: I18N.t('已取消') },
  REFUNDED: { text: I18N.t('已退款') },
};

export const InvoiceTypeEnum = {
  Personal: { text: I18N.t('个人普通发票') },
  EntNormal: { text: I18N.t('电子发票（普通发票）') },
  EntSpecial: { text: I18N.t('电子发票（增值税专用发票）') },
};
export const InvoiceStatusEnum = {
  Applied: { text: I18N.t('已申请') },
  Invoiced: { text: I18N.t('已开票') },
  NotInvoiced: { text: I18N.t('未开票') },
};

export const ProductionStatusEnum = {
  Finished: {
    text: I18N.t('生产成功'),
  },
  NotStart: {
    text: I18N.t('未开始'),
  },
  ProduceError: {
    text: I18N.t('生产失败'),
  },
  Producing: {
    text: I18N.t('生产中'),
  },
  // | 'ReFunded'
  // | 'RefundError'
  // | 'Refunding'
  // | 'WaitReFund';
};
export const CreditDealTypeEnum = {
  BuyCredit: I18N.t('购买花瓣'),
  ConsumeCredit: I18N.t('消耗花瓣'),
  InitPresent: I18N.t('初始赠送'),
  Present: I18N.t('赠送花瓣'),
  TransferIn: I18N.t('花瓣转入'),
  GiftCard: I18N.t('花瓣礼品卡激活'),
  OrderRefund: I18N.t('订单退款'),
  RpaMarketFlow: I18N.t('RPA市场流程'),
  RpaSendEmail: I18N.t('RPA发送邮件'),
  RpaSendWeChat: I18N.t('RPA发送微信模板消息'),
  RpaSendSms: I18N.t('RPA发送短信'),
  IpOverQuotaTraffic: I18N.t('超额流量'),
  ProxyTraffic: I18N.t('代理流量'),
  RpaExecuteQuota: I18N.t('RPA执行'),
  RpaOpenAi: I18N.t('RPA智能AI'),
  ShopQuota: I18N.t('分身数量'),
  ShopSecurityPolicy: I18N.t('分身安全策略'),
  StorageQuota: I18N.t('存储空间'),
  TeamMemberQuota: I18N.t('团队成员'),
  TransferOut: I18N.t('花瓣转出'),
  TransitTraffic: I18N.t('中转流量'),
  RpaCaptcha: I18N.t('RPA验证码识别'),
};
