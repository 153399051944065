import I18N from '@/i18n';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer, DatePicker, Form, Button, Badge, Space, Typography, Tabs } from 'antd';
import { useModel } from 'umi';
import type { ProColumns } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import styles from './index.less';
import { scrollProTableOptionFn } from '@/mixins/table';
import {
  msgCenterMessagesGet,
  msgCenterMarkAllHasReadPut,
  msgCenterUnreadCountGet,
} from '@/services/api-MessageCenterAPI/MessageCenterController';
import MessageCenterConstants from '@/constants/MessageCenterConstants';
import ajaxEventClient from '@/utils/AjaxEventClient';
import IconFontIcon from '@/components/Common/IconFontIcon';
import MessageDetail from '@/components/MessageCenter/components/MessageDetail';
import ColoursIcon from '@/components/Common/ColoursIcon';
import MissionCenter from '@/components/MissionCenter';

/**
 * 消息中心
 * @constructor
 */
const MessageCenter: FC = () => {
  const { currentTeam } = useModel('currentTeam');
  const { unreadMsgCountList, setUnreadMsgCountList } = useModel('messageCenter');
  const [currentMessage, setCurrentMessage] = useState<API.UserMessageVo | null>(null);
  const [filterForm] = Form.useForm();
  const paramsTmp = useRef({
    messageZoo: '',
    current: 1,
  });

  const loadData = useCallback(
    async (params = {}) => {
      // 更新未读计数
      if (currentTeam) {
        const { data: unreadData } = await msgCenterUnreadCountGet({
          targetTeamId: currentTeam?.id,
        });
        setUnreadMsgCountList(unreadData ?? []);
      }
      const { createTime, current, ...otherParams } = params;
      // 缓存查询参数
      paramsTmp.current = { ...paramsTmp.current, ...params };
      const queryObj: any = {
        pageNum: paramsTmp.current.current,
        pageSize: 20,
        ...otherParams,
      };
      if (createTime?.[0]) {
        queryObj.from = createTime[0];
      }
      if (createTime?.[1]) {
        queryObj.to = createTime[1];
      }
      const { success, data } = await msgCenterMessagesGet(queryObj);
      return { success, data: data?.list, total: data?.total };
    },
    [currentTeam],
  );

  useEffect(() => {
    if (!currentTeam) return;
    let hd: string;
    // 团队切换，不显示详情了
    setCurrentMessage(null);
    (async () => {
      hd = await ajaxEventClient.on('msg-center-new-message', 0, () => {
        filterForm.submit();
      });
    })();
    return () => {
      if (hd) {
        ajaxEventClient.un(hd);
      }
    };
  }, [currentTeam]);

  useEffect(() => {
    filterForm.submit();
  }, [currentTeam]);

  const columns: ProColumns<API.UserMessageVo>[] = [
    {
      title: I18N.t('消息内容'),
      dataIndex: 'summary',
      ellipsis: true,
      className: classNames(styles.firstCell),
      search: false,
      render: (dom, messageVo) => {
        const { summary, messageZoo, readTime, messageType } = messageVo;
        return (
          <Space style={{ cursor: 'pointer' }} onClick={() => setCurrentMessage(messageVo)}>
            <Badge dot={!readTime}>{getIconByMessageZoo(messageZoo, messageType)}</Badge>
            <Typography.Text ellipsis title={summary} style={{ maxWidth: 400 }}>
              {summary}
            </Typography.Text>
          </Space>
        );
      },
    },
    {
      title: I18N.t('消息类型'),
      dataIndex: 'messageType',
      ellipsis: true,
      className: 'ant-table-cell-ellipsis',
      width: '140px',
      render: (dom, { messageType }) => {
        return MessageCenterConstants.messageTypeNameMap[messageType!];
      },
      search: false,
    },
    {
      key: 'messageZoo',
      hideInTable: true,
      renderFormItem: () => {
        const unreadCount = {};
        _.forEach(unreadMsgCountList, (item) => {
          unreadCount[item.messageZoo!] = item.msgCount;
        });
        const btns = [
          { key: '', label: I18N.t('全部类型'), count: _.sum(_.values(unreadCount)) },
        ].concat(
          _.map(MessageCenterConstants.messageZooNameMap, (name, key) => ({
            key,
            label: name,
            count: unreadCount[key] ?? 0,
          })),
        );
        return (
          <Space className={styles.messageZooFilterWrap} size={10}>
            {btns.map(({ key, label, count }) => (
              <Badge key={key} count={count}>
                <Button
                  type={paramsTmp.current.messageZoo === key ? 'primary' : 'default'}
                  style={{ minWidth: 80 }}
                  onClick={() => {
                    filterForm.setFieldsValue({ messageZoo: key });
                    filterForm.submit();
                  }}
                >
                  {label}
                </Button>
              </Badge>
            ))}
          </Space>
        );
      },
    },
    {
      title: I18N.t('发送时间'),
      dataIndex: 'createTime',
      valueType: 'dateTimeRange',
      width: '190px',
      render: (text: any, { createTime }: any) => moment(createTime).format('YYYY-MM-DD HH:mm:ss'),
      renderFormItem: () => (
        <DatePicker.RangePicker
          placeholder={[I18N.t('开始时间'), I18N.t('结束时间')]}
          style={{ width: 220 }}
          onChange={(dates, strings) => {
            filterForm.setFieldsValue({
              createTime: dates,
            });
            filterForm.submit();
          }}
        />
      ),
    },
    {
      title: I18N.t('操作'),
      key: 'opt',
      search: false,
      width: '72px',
      render: (dom, messageVo) => (
        <a onClick={() => setCurrentMessage(messageVo)}>{I18N.t('查看')}</a>
      ),
    },
  ];

  return (
    <>
      <ProTable<API.UserMessageVo>
        columns={columns}
        request={loadData}
        {...scrollProTableOptionFn({
          className: styles.wrap,
          rowKey: 'id',
          rowClassName: (row) => (row.readTime ? styles.read : ''),
          options: false,
          toolBarRender: () => [],
          dateFormatter: 'string',
          search: {
            className: styles.searchWrap,
            collapsed: false,
            collapseRender: false,
            form: filterForm,
            optionRender: false,
          },
          toolbar: {
            style: { display: 'none' },
          },
          pageId: 'messageCenter',
          pagination: { pageSize: 20 },
          footer: () => {
            return (
              <Button
                ghost
                type="primary"
                onClick={async () => {
                  await msgCenterMarkAllHasReadPut();
                  filterForm.submit();
                }}
              >
                {I18N.t('全部标为已读')}
              </Button>
            );
          },
        })}
      />

      <MessageDetail
        message={currentMessage}
        setCurrentMessage={(message) => setCurrentMessage(message)}
        onClose={() => setCurrentMessage(null)}
        reloadMessageList={() => filterForm.submit()}
      />
    </>
  );
};

function getIconByMessageZoo(messageZoo: string | undefined, messageType?: string) {
  if (messageZoo === 'Shops') {
    return (
      <span className={styles.msgIcon}>
        <ColoursIcon className="Chrome_24" size={18} />
      </span>
    );
  }
  if (messageZoo === 'Ips') {
    return (
      <span className={styles.msgIcon} style={{ backgroundColor: '#FFC53D' }}>
        <IconFontIcon iconName="IP_24" />
      </span>
    );
  }
  if (['Team', 'Audit'].includes(messageZoo!)) {
    if (messageType === 'Critical_Message') {
      // 返回红色的图标
      return (
        <span className={styles.msgIcon} style={{ backgroundColor: '#ff4d4f' }}>
          <IconFontIcon iconName="tuandui_24" />
        </span>
      );
    }
    return (
      <span className={styles.msgIcon} style={{ backgroundColor: '#73D13D' }}>
        <IconFontIcon iconName="tuandui_24" />
      </span>
    );
  }
  if (messageZoo === 'Payment') {
    return (
      <span className={styles.msgIcon} style={{ backgroundColor: '#3b6ae0' }}>
        <IconFontIcon iconName="feiyong_24" />
      </span>
    );
  }
  if (messageZoo === 'Activity') {
    return (
      <span className={styles.msgIcon}>
        <ColoursIcon size={18} className="xiaoxi-tuiguang_24" />
      </span>
    );
  }
  return (
    <span className={styles.msgIcon} style={{ backgroundColor: '#f98ecb' }}>
      <IconFontIcon iconName="info_24" />
    </span>
  );
}

const Wrapper: FC = (props) => {
  const { visible, hide } = useModel('messageCenter');

  return (
    <Drawer
      width={860}
      style={{
        overflow: 'hidden',
      }}
      visible={visible}
      onClose={() => {
        hide();
      }}
      className={styles.drawer}
      getContainer={() => document.querySelector('#root') ?? document.body}
    >
      <Tabs centered defaultActiveKey="message" className={styles.tabs}>
        <Tabs.TabPane key="message" tab={I18N.t('消息中心')}>
          <MessageCenter />
        </Tabs.TabPane>
        <Tabs.TabPane key="mission" tab={I18N.t('任务中心')}>
          <MissionCenter visible={visible} />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
};

export default Wrapper;
