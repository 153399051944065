import I18N from '@/i18n';

export default {
  messageZooNameMap: {
    Team: I18N.t('团队管理'),
    Ips: I18N.t('IP管理'),
    Shops: I18N.t('分身管理'),
    Payment: I18N.t('费用中心'),
    Activity: I18N.t('运营推广'),
  },
  messageTypeNameMap: {
    Ip_Product_Start: I18N.t('IP生产'),
    Ip_Product_Success: I18N.t('IP生产成功'),
    Ip_Product_Failed: I18N.t('IP生产失败'),
    Ip_Import: I18N.t('IP导入'),
    Ip_UnImport: I18N.t('IP移除'),
    Ip_Will_Expire: I18N.t('IP到期'),
    Ip_Expire: I18N.t('IP过期'),
    Ip_Destroyed: I18N.t('IP销毁成功'),
    Ip_Will_Destroy: I18N.t('IP即将销毁'),
    Ip_Renew_Success: I18N.t('IP续费成功'),
    Ip_Renew_Failed: I18N.t('IP续费失败'),
    Ip_Address_Changed: I18N.t('IP切换'),

    Shop_Transfer_Audit_Notify: I18N.t('转让分身审核'),
    Shop_Transfer_Audit_Pass: I18N.t('转让分身审核'),
    Shop_Transfer_Audit_Reject: I18N.t('转让分身审核'),
    Shop_Info_ReportSuccess: I18N.t('分身克隆成功'),
    Shop_Receive_Notify: I18N.t('分身转让确认'),
    Shop_Receive_Result: I18N.t('分身转让结果'),
    Shop_Sharing_Notify: I18N.t('分身联营确认'),
    Shop_Sharing_Result: I18N.t('分身联营结果'),

    User_Join_Team: I18N.t('成员加入'),
    User_Join_Team_Audit_Notify: I18N.t('成员加入审批'),
    User_Join_Team_Audit_Pass: I18N.t('成员加入审批'),
    User_Join_Team_Audit_Reject: I18N.t('成员加入审批'),
    User_Exit_team: I18N.t('成员退出'),
    User_New_Device: I18N.t('新设备登录'),
    Domain_White_Pass: I18N.t('网址加白成功'),
    Domain_White_Reject: I18N.t('网址加白失败'),

    Bank_Pay_Confirmed: I18N.t('银行转账到账通知'),
    New_Order_Need_Pay: I18N.t('待支付订单通知'),
    Credit_Buy_Notify: I18N.t('花瓣购买通知'),

    Activity: I18N.t('运营推广'),
    Critical_Message: I18N.t('关键事项检查'),
    General: I18N.t('通用消息'),
    Crs_Orders_Fetched: I18N.t('CRS数据同步'),
    Kakao_Chats_Synced: I18N.t('Kakao消息同步'),
    OP_Message: I18N.t('运营公告'),
  },
};
