import I18N from '@/i18n';
import type { ReactNode } from 'react';
import type { ButtonProps, ModalFuncProps } from 'antd';
import { cloneElement } from 'react';
import { Modal, Button } from 'antd';
import IconFontIcon from '@/components/Common/IconFontIcon';
import classNames from 'classnames';
import styles from './DMConfirm.less';
import { DMContextProvider } from '@/components/Common/Modal/DMModal';

type CustomBtnProps = {
  btnProps?: ButtonProps;
  key?: 'ok' | 'cancel' | string;
  label?: ReactNode;
  onClick?: () => void;
  content?: ReactNode;
};

export interface Options extends ModalFuncProps {
  footerBtns?: CustomBtnProps[];
  type?: 'info' | 'success' | 'error' | 'warn' | 'confirm';
  iconType?: 'info' | 'success' | 'error' | 'warn' | 'confirm' | 'loading';
}

const Types = {
  info: 'info_241',
  success: 'chenggong_24',
  error: 'shibai_24',
  warn: 'jinggao_24',
  confirm: 'bangzhu_241',
  loading: 'loading_24',
};

const DMConfirm = (options: Options) => {
  const {
    type,
    icon,
    iconType,
    className,
    okText = I18N.t('确定'),
    cancelText = I18N.t('取消'),
    footerBtns = [],
    content,
    ...otherProps
  } = options;
  const method = type || 'confirm';
  const modalRef = Modal[method]({
    style: {
      minWidth: Math.min(window.screen.width, 440),
    },
    ...otherProps,
    content: (
      <DMContextProvider>
        {content}
        {footerBtns.length > 0 && (
          <div className={styles.customFooter}>
            {footerBtns.map((b) => {
              if (b.content) {
                return cloneElement(b.content, {
                  ...b.btnProps,
                  ...b.content.props,
                  key: b.key,
                  onClick: b.content.props?.onClick
                    ? b.content.props?.onClick
                    : () => {
                        modalRef.destroy();
                        if (b.onClick) {
                          b.onClick();
                        }
                      },
                });
              }
              return (
                <Button
                  {...b.btnProps}
                  key={b.key}
                  onClick={() => {
                    modalRef.destroy();
                    if (b.onClick) {
                      b.onClick();
                    }
                  }}
                >
                  {b.label}
                </Button>
              );
            })}
          </div>
        )}
      </DMContextProvider>
    ),

    centered: true,
    okText: method !== 'confirm' && !options.okText ? I18N.t('知道了') : okText,
    cancelText,
    className: classNames(className, styles.DMConfirm, {
      [styles.isCustomFooter]: footerBtns.length > 0,
    }),
    icon:
      icon === undefined ? (
        <IconFontIcon
          spin={iconType === 'loading'}
          iconName={iconType ? Types[iconType] : Types[method]}
          className={classNames('anticon', method, iconType)}
        />
      ) : (
        icon
      ),
  });
  return modalRef;
};

export const DMLoading = (props: {
  title: ReactNode;
  content?: ReactNode;
  width?: number;
  [key: string]: any;
}) => {
  return DMConfirm({
    icon: (
      <IconFontIcon iconName="loading_24" className={classNames('anticon', styles.loadingIcon)} />
    ),

    okButtonProps: { disabled: true, hidden: true },
    cancelButtonProps: { hidden: true },
    cancelText: I18N.t('关闭'),
    ...props,
  });
};

export default DMConfirm;
