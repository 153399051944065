import { emitEvent, isElectron, sendSync } from '@/utils/ElectronUtils';
import { accountRefreshTokenGet as refreshToken } from '@/services/api-Account/LoginController';
import { SkipErrorNotifyOption } from '@/utils/utils';

let jwt = '';
export const getJwt = () => {
  if (isElectron()) {
    jwt = jwt || sendSync('get-jwt', {});
  } else {
    jwt = jwt || localStorage.getItem('jwt') || '';
  }
  return jwt;
};
export const setJwt = (newJwt: string) => {
  jwt = newJwt;

  // jwt失效后清除 localStorage
  if (isElectron()) {
    // 本地缓存登录态
    emitEvent('jwt-update', { jwt });
  } else if (!jwt) {
    localStorage.removeItem('jwt');
  } else {
    localStorage.setItem('jwt', jwt);
  }
};
export function refreshJwt() {
  refreshToken(SkipErrorNotifyOption)
    .then((res) => {
      if (res?.data) {
        setJwt(res.data.jwt!);
      }
    })
    .catch(() => {
      setJwt('');
    });
}
