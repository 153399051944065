import I18N from '@/i18n';
import type { Ref } from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from 'antd';
import type { FormBaseProps } from '@/components/UserCenter/UserSetting';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import FormItem from '@/components/UserCenter/components/FormItem';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { useAutoInstallTimeRangeSettingsModal } from '@/components/UserCenter/components/AutoInstallTimeRangeSettingsModal';
import SwitchLocale from '@/i18n/SwitchLocale';
import { getAppProxy, isElectron, sendAsync, setAppProxy } from '@/utils/ElectronUtils';
import { getSysPresSettings, setSysPresSettings } from '@/i18n/I18N';
import useCurrentDevice from '@/hooks/useCurrentDevice';
import CopyableText from '@/components/Common/CopyableText';

const networkTypeEnum = {
  direct: I18N.t('不使用代理'),
  http: I18N.t('HTTP代理'),
  socks5: I18N.t('SOCKS5代理'),
  system: I18N.t('使用浏览器设置'),
};

/**
 * 系统偏好设置
 * @param props
 * @param ref
 * @constructor
 */
function SystemPrefForm(
  props: FormBaseProps & {
    hidePreventSleep?: boolean;
    hideExitOnClose?: boolean;
    hideWinSyncPauseKey?: boolean;
  },
  ref: Ref<unknown> | undefined,
) {
  const {
    editing,
    hidePreventSleep = false,
    hideExitOnClose = false,
    hideWinSyncPauseKey = false,
  } = props;
  const [v, update] = useState(0);
  const [formIns] = Form.useForm();
  const device = useCurrentDevice();
  const showAutoInstallTimeRangeSettingsModal = useAutoInstallTimeRangeSettingsModal();
  const sysPresSettings = useMemo(() => getSysPresSettings(), []);
  const proxyConfig = useMemo(() => {
    const { mode = 'direct', proxyRules = '' } = getAppProxy();
    return {
      proxyMode: ['direct', 'system'].includes(mode) ? mode : proxyRules.split('://')[0],
      proxyHost: proxyRules ? proxyRules.split('://')[1].split(':')[0] : undefined,
      proxyPort: proxyRules ? proxyRules.split('://')[1].split(':')[1] : undefined,
    };
  }, []);

  useEffect(() => {
    update(v + 1);
  }, []);
  useImperativeHandle(ref, () => {
    return {
      submit: async () => {
        await formIns.validateFields();
        const fieldsValue = formIns.getFieldsValue();
        const sysPresSettings = getSysPresSettings();
        if (isElectron()) {
          await setSysPresSettings({ ...sysPresSettings, ...fieldsValue });
          const proxyConfig: Record<string, any> = {};
          if (['direct', 'system'].includes(fieldsValue.proxyMode)) {
            proxyConfig.mode = fieldsValue.proxyMode;
          } else {
            proxyConfig.mode = 'fixed_servers';
            proxyConfig.proxyRules = `${fieldsValue.proxyMode}://${fieldsValue.proxyHost}:${fieldsValue.proxyPort}`;
          }
          await sendAsync('set-app-proxy', proxyConfig).catch((e) => message.error(e.message));
          setAppProxy(proxyConfig);
          if (sysPresSettings.appLang !== fieldsValue.appLang) {
            I18N.isCn() ? I18N.switchToEn() : I18N.switchToCn();
          }
        } else {
          // 浏览器环境下，只有语言可以修改
          if (fieldsValue.appLang !== I18N.getLocale()) {
            I18N.isCn() ? I18N.switchToEn() : I18N.switchToCn();
          }
        }
      },
      resetFields() {
        formIns.resetFields();
      },
    };
  });

  const getAutoUpdateText = useCallback(() => {
    if (formIns.getFieldValue('autoUpdate') === 0b11) {
      return `${I18N.t('静默安装（升级时段：')}${formIns.getFieldValue('autoInstallTimeRange')}）`;
    }
    if (formIns.getFieldValue('autoUpdate')) {
      return I18N.t('自动下载');
    }
    return I18N.t('仅提醒');
  }, [formIns]);
  return (
    <DMFormItemContext.Provider value={{ labelWidth: 150 }}>
      <Form
        form={formIns}
        requiredMark={false}
        initialValues={{
          ...sysPresSettings!,
          ...proxyConfig,
        }}
      >
        <DMFormItem label={I18N.t('当前设备标识')}>
          <CopyableText text={device?.deviceId} type={I18N.t('设备标识')}>
            <span style={{ fontFamily: 'Consolas, Courier New, monospace' }}>
              {device?.deviceId}
            </span>
          </CopyableText>
        </DMFormItem>
        {isElectron() && (
          <FormItem label={I18N.t('开机启动')} name="autoLaunch" valuePropName="checked">
            {editing ? (
              <Switch checkedChildren={I18N.t('开启')} unCheckedChildren={I18N.t('关闭')} />
            ) : (
              <span>{formIns.getFieldValue('autoLaunch') ? I18N.t('开启') : I18N.t('关闭')}</span>
            )}
          </FormItem>
        )}
        {isElectron() && (
          <>
            <FormItem label={I18N.t('客户端网络设置')} name="proxyMode">
              {!editing ? (
                networkTypeEnum[formIns.getFieldValue('proxyMode')]
              ) : (
                <Select>
                  <Select.Option value="direct">{I18N.t('不使用代理')}</Select.Option>
                  <Select.Option value="http">{I18N.t('HTTP代理')}</Select.Option>
                  <Select.Option value="socks5">{I18N.t('SOCKS5代理')}</Select.Option>
                  <Select.Option value="system">{I18N.t('使用浏览器设置')}</Select.Option>
                </Select>
              )}
            </FormItem>
            <DMFormItem noStyle shouldUpdate>
              {(f) => {
                const type = f.getFieldValue('proxyMode');
                if (type === 'direct' || type === 'system') {
                  return null;
                }
                if (!editing) {
                  return (
                    <DMFormItem label={I18N.t('服务器地址')}>
                      {formIns.getFieldValue('proxyHost')}:{formIns.getFieldValue('proxyPort')}
                    </DMFormItem>
                  );
                }
                return (
                  <Row gutter={16}>
                    <Col span={16}>
                      <FormItem
                        name="proxyHost"
                        label={I18N.t('服务器地址')}
                        rules={[
                          {
                            required: true,
                            message: I18N.t('请输入{{label}}', {
                              label: I18N.t('服务器地址'),
                            }),
                          },
                        ]}
                      >
                        <Input />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        labelWidth={60}
                        name="proxyPort"
                        label={I18N.t('端口')}
                        rules={[
                          {
                            required: true,
                            message: I18N.t('请输入{{label}}', {
                              label: I18N.t('端口'),
                            }),
                          },
                        ]}
                      >
                        <InputNumber precision={0} min={1} max={65535} style={{ width: '100%' }} />
                      </FormItem>
                    </Col>
                  </Row>
                );
              }}
            </DMFormItem>
          </>
        )}
        <FormItem
          label={I18N.t('客户端语言')}
          name="appLang"
          hidden={document.querySelector('body')?.className.includes('custom-window-title-bar')}
        >
          {editing ? (
            <SwitchLocale type={'select'} />
          ) : (
            <SwitchLocale value={formIns.getFieldValue('appLang')} />
          )}
        </FormItem>
        {isElectron() && (
          <FormItem
            label={I18N.t('禁止电脑休眠')}
            name="preventSleep"
            hidden={hidePreventSleep}
            valuePropName="checked"
            tooltip={I18N.t(
              '当开启时，则电脑将不会自动进入休眠状态，该场景适用于需要定时运行RPA流程计划等场景',
            )}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {editing ? (
              <Switch checkedChildren={I18N.t('开启')} unCheckedChildren={I18N.t('关闭')} />
            ) : formIns.getFieldValue('preventSleep') ? (
              I18N.t('开启')
            ) : (
              I18N.t('关闭')
            )}
          </FormItem>
        )}
        {isElectron() && (
          <FormItem
            label={I18N.t('关闭主窗口时')}
            name="exitOnClose"
            hidden={hideExitOnClose}
            tooltip={I18N.t(
              '当关闭花漾主窗口时，可以是强制退出（所有打开的花漾浏览器会自动关闭），也可以隐藏到任务栏（通过任务栏可再次激活花漾客户端）',
            )}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {editing ? (
              <Radio.Group>
                <Radio value={true} style={{ width: 110 }}>
                  {I18N.t('退出')}
                </Radio>
                <Radio value={false}>{I18N.t('隐藏到任务栏')}</Radio>
              </Radio.Group>
            ) : formIns.getFieldValue('exitOnClose') ? (
              I18N.t('退出')
            ) : (
              I18N.t('隐藏到任务栏')
            )}
          </FormItem>
        )}
        {isElectron() && (
          <DMFormItem noStyle shouldUpdate={true}>
            {({ getFieldValue }) => (
              <FormItem
                label={I18N.t('发现新版本时')}
                name="autoUpdate"
                tooltip={
                  <>
                    <div style={{ marginBottom: 8 }}>
                      {I18N.t('仅提醒：有新版本时，在客户端左下角发出提醒，由用户自行决定是否下载')}
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      {I18N.t(
                        '自动下载：自动下载最新版本，在客户端左下角发出提醒，由用户自行决定是否安装',
                      )}
                    </div>
                    <div>
                      {I18N.t(
                        '静默安装：不仅自动下载最新版本，还会在指定时间内且客户端空闲时（指没有打开任何花漾浏览器时）自动完成静默安装，适用于客户端长期值守运行RPA流程等场景',
                      )}
                    </div>
                  </>
                }
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {editing ? (
                  <Radio.Group>
                    <Radio value={0b00} style={{ minWidth: 110 }}>
                      {I18N.t('仅提醒')}
                    </Radio>
                    <Radio value={0b01} style={{ minWidth: 110 }}>
                      {I18N.t('自动下载')}
                    </Radio>
                    <Radio value={0b11} style={{ minWidth: 110 }}>
                      <Tooltip
                        title={`${I18N.t('自动升级时段：')}${getFieldValue(
                          'autoInstallTimeRange',
                        )}`}
                      >
                        {I18N.t('静默安装')}
                        <a
                          style={{ marginLeft: 8 }}
                          onClick={() => {
                            showAutoInstallTimeRangeSettingsModal({ formIns });
                          }}
                        >
                          <IconFontIcon iconName="jihua_24" size={16} />
                        </a>
                      </Tooltip>
                    </Radio>
                  </Radio.Group>
                ) : (
                  getAutoUpdateText()
                )}
              </FormItem>
            )}
          </DMFormItem>
        )}
        {isElectron() && (
          <DMFormItem
            label={I18N.t('群控快捷键')}
            hidden={hideWinSyncPauseKey}
            tooltip={I18N.t('群控启动/暂停的快捷键')}
            style={{ marginBottom: 0 }}
          >
            {editing ? (
              <DMFormItem name="winSyncPauseKey" noStyle>
                <WinSyncPauseKey />
              </DMFormItem>
            ) : (
              <span>
                {(formIns.getFieldValue('winSyncPauseKey') || 'Ctrl+Shift+S')
                  .split('+')
                  .join(' + ')}
              </span>
            )}
          </DMFormItem>
        )}
        <FormItem label="" name="autoInstallTimeRange" hidden noStyle />
      </Form>
    </DMFormItemContext.Provider>
  );
}

function WinSyncPauseKey({ value, onChange }: any) {
  const [, _modKey = 'Shift', _charKey = 'S'] = (value || '').split('+');
  const [modKey, setModKey] = useState(_modKey);
  const [charKey, setCharKey] = useState(_charKey);

  useEffect(() => {
    onChange(`Ctrl+${modKey}+${charKey}`);
  }, [charKey, modKey, onChange]);

  return (
    <Space>
      <span>Ctrl</span>
      <span>+</span>
      <Select
        value={modKey}
        onChange={(v) => {
          setModKey(v);
        }}
      >
        <Select.Option value="Shift">Shift</Select.Option>
        <Select.Option value="Alt">Alt</Select.Option>
      </Select>
      <span>+</span>
      <Input
        value={charKey}
        readOnly
        style={{ width: 50, textAlign: 'center' }}
        onKeyDown={(e) => {
          if (/^[a-z0-9]$/.test(e.key)) {
            setCharKey(e.key.toUpperCase());
          }
        }}
      />
    </Space>
  );
}

export default forwardRef(SystemPrefForm);
