import I18N from '@/i18n';
import keyMirror from 'keymirror';
import ShopConstans from './ShopConstans';

const { IS_PRODUCTION } = process.env;
const isProduction = IS_PRODUCTION === 'true';
const loginPath = '/login';
// 不需要登录的页面
const publicPath = [
  loginPath,
  '/register',
  '/accessDeny',
  '/invite/',
  '/license',
  '/fingerprint',
  '/findPassword',
  '/wx',
  '/link/',
  '/rpaCaptcha',
  '/rpaSelectorPicker',
  '/result/',
  '/ipMarket',
  '/shortcut',
];

export const MAX_UA_VERSION = 128;

export default {
  hotline: '0755-82520789',
  latestBrowserEngineVersion: MAX_UA_VERSION,
  siteName: I18N.t('花漾灵动'),
  siteLink: 'https://www.thinkoncloud.com',
  productSite: 'https://www.szdamai.com',
  companyName: I18N.t('深圳市云上悦动科技有限公司'),
  companyDomain: 'thinkoncloud.com',
  appDownloadLink: `/download`,
  // 区分是否是从客户端里面点出来的链接
  appDownloadLinkFromApp: `/download?TGID=PgYRCH`,
  extCookDownloadLink: `/downloads/chrome_plugin.zip`,
  extCookChromeLink: 'https://chrome.google.com/webstore/detail/hfofehicjnhkhohbplleimddnmglpkei',
  extCookEdgeLink:
    'https://microsoftedge.microsoft.com/addons/detail/alcaljobnkddfagkikcdhfjkdklofgdb',
  rpaStore: '/store/rpa/All',
  // 企点技术支持
  qdSupportLink: 'https://wpa1.qq.com/QiQV1aj0?_type=wpa&qidian=true',
  // 企点商务谈判
  qdBusinessLink: 'https://wpa1.qq.com/QIpN7vJ1?_type=wpa&qidian=true',
  downloadPath: isProduction
    ? 'https://dl.szdamai.com/downloads/'
    : 'https://dev.thinkoncloud.cn/downloads/',
  tkSite: isProduction ? 'https://tk.szdamai.com/' : 'https://tk.thinkoncloud.cn/',
  loginPath,
  publicPath,
  regs: {
    cellPhone: /^1[3-9]\d{9}$/,
    internationalCellPhone: /^1[3-9]\d{9}$/,
    ipv4: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    ipv6: /^([\da-fA-F]{1,4}:){6}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^::([\da-fA-F]{1,4}:){0,4}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:):([\da-fA-F]{1,4}:){0,3}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){2}:([\da-fA-F]{1,4}:){0,2}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){3}:([\da-fA-F]{1,4}:){0,1}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){4}:((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)$|^([\da-fA-F]{1,4}:){7}[\da-fA-F]{1,4}$|^:((:[\da-fA-F]{1,4}){1,6}|:)$|^[\da-fA-F]{1,4}:((:[\da-fA-F]{1,4}){1,5}|:)$|^([\da-fA-F]{1,4}:){2}((:[\da-fA-F]{1,4}){1,4}|:)$|^([\da-fA-F]{1,4}:){3}((:[\da-fA-F]{1,4}){1,3}|:)$|^([\da-fA-F]{1,4}:){4}((:[\da-fA-F]{1,4}){1,2}|:)$|^([\da-fA-F]{1,4}:){5}:([\da-fA-F]{1,4})?$|^([\da-fA-F]{1,4}:){6}:$ /,
    url: /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
    urlIgnoreProtocol: /^(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
    hostname: /^([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
    wildHostname: /^(\*\.)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}$/,
    email: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    port: /^(?:[1-9]\d{0,4}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/,
    chinese: /[\u4e00-\u9fa5]/,
    emoji: /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f\ude80-\udeff])|[\u2600-\u2B55]/,
  },
  defaultAvatar: '/common/defaultAvatar.jpg',
  IpImportType: {
    Platform: I18N.t('平台提供'),
    User: I18N.t('自有IP（VPS）'),
  },
  IpPipeType: {
    Tunnel: I18N.t('花漾IPGO代理'),
    Proxy: I18N.t('用户自有代理'),
  },
  IpStatus: {
    Available: I18N.t('有效'),
    Unavailable: I18N.t('失效'),
    Pending: I18N.t('准备'),
  },
  SocksType: {
    socks5: 'Socks5',
    socks4: 'Socks4',
  },
  Area: {
    Australia: I18N.t('澳大利亚'),
    Brazil: I18N.t('巴西'),
    Canada: I18N.t('加拿大'),
    China: I18N.t('中国大陆'),
    France: I18N.t('法国'),
    Germany: I18N.t('德国'),
    India: I18N.t('印度'),
    Italy: I18N.t('意大利'),
    Japan: I18N.t('日本'),
    Mexico: I18N.t('墨西哥'),
    Netherlands: I18N.t('荷兰'),
    Poland: I18N.t('波兰'),
    Saudi_Arabia: I18N.t('沙特阿拉伯'),
    Singapore: I18N.t('新加坡'),
    Spain: I18N.t('西班牙'),
    Sweden: I18N.t('瑞典'),
    Turkey: I18N.t('土耳其'),
    United_Arab_Emirates: I18N.t('阿联酋'),
    United_Kingdom: I18N.t('英国'),
    United_States: I18N.t('美国'),
    Argentina: I18N.t('阿根廷'),
    Ireland: I18N.t('爱尔兰'),
    Austria: I18N.t('奥地利'),
    Paraguay: I18N.t('巴拉圭'),
    Panama: I18N.t('巴拿马'),
    Belarus: I18N.t('白俄罗斯'),
    Belgium: I18N.t('比利时'),
    Puerto_Rico: I18N.t('波多黎各'),
    Bolivia: I18N.t('玻利维亚'),
    Dominican: I18N.t('多米尼加'),
    Russia: I18N.t('俄罗斯'),
    Ecuador: I18N.t('厄瓜多尔'),
    Philippines: I18N.t('菲律宾'),
    Colombia: I18N.t('哥伦比亚'),
    Costa_Rica: I18N.t('哥斯达黎加'),
    Kazakhstan: I18N.t('哈萨克斯坦'),
    Korea: I18N.t('韩国'),
    Honduras: I18N.t('洪都拉斯'),
    Malaysia: I18N.t('马来西亚'),
    Peru: I18N.t('秘鲁'),
    Nicaragua: I18N.t('尼加拉瓜'),
    Portuguese: I18N.t('葡萄牙'),
    Switzerland: I18N.t('瑞士'),
    Salvador: I18N.t('萨尔瓦多'),
    Guatemala: I18N.t('危地马拉'),
    Venezuela: I18N.t('委内瑞拉'),
    Uruguay: I18N.t('乌拉圭'),
    Israel: I18N.t('以色列'),
    Chile: I18N.t('智利'),
    Taiwan: I18N.t('中国台湾'),
    HongKong: I18N.t('中国香港'),
    Indonesia: I18N.t('印度尼西亚'),
    Thailand: I18N.t('泰国'),
    Vietnam: I18N.t('越南'),
    Egypt: I18N.t('埃及'),
    Global: I18N.t('全球'),
  },
  Gender: {
    UNSPECIFIC: I18N.t('保密'),
    MALE: I18N.t('男'),
    FEMALE: I18N.t('女'),
  },
  PlatformType: {
    Amazon: I18N.t('亚马逊'),
    eBay: 'eBay',
    Wish: 'Wish',
    Shopee: 'Shopee',
    Lazada: 'Lazada',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    TikTok: 'TKShop',
    IM: I18N.t('社交媒体'),
    TkVideo: 'TKVideo',
    YouTube: 'YouTube',
    PayPal: 'PayPal',
    PingPong: 'PingPong',
    Alibaba: I18N.t('阿里巴巴'),
    Etsy: 'Etsy',
    Douyin: I18N.t('抖音'),
    Bilibili: I18N.t('哔哩哔哩'),
    Zhihu: I18N.t('知乎'),
    Instagram: 'Instagram',
    Coupang: 'Coupang',
    Naver: 'Naver',
    Gmarket: 'Gmarket',
    TMon: 'TMon',
    WeMakePrice: 'WeMakePrice',
    Other: I18N.t('其他'),
  },
  UserStatus: {
    INACTIVE: I18N.t('未激活'),
    ACTIVE: I18N.t('正常'),
    BLOCK: I18N.t('已禁用'), // 请注意,当前该状态还没有设置,判断锁定或者禁用用户,需要通过user_acl表去确定
    DELETED: I18N.t('标记为删除'),
  },
  AuditType: {
    JOIN_TEAM: I18N.t('加入团队'),
    TRANSFER_SHOP: I18N.t('转让分身'),
    RECEIVE_SHOPS: I18N.t('接收分身'),
    SHARED_SHOPS: I18N.t('联营分身'),
    SHARED_SHOP: I18N.t('联营分身'),
    NEW_DEVICE: I18N.t('新设备登录'),
  },
  JoinTeamStatus: keyMirror({
    JOIN_SUCCESS: null, // 加入成功
    WAIT_AUDIT: null, // 待审核
  }),
  AuditStatus: keyMirror({
    NEW: null, // 新建,
    APPROVED: null, // 审批通过,
    NOT_PASS: null, // 审批未通过
    CANCEL: null, // 取消
  }),
  errorMessages: {
    'reg.phone_exist': I18N.t('手机号已存在'),
    'not a team member': I18N.t('您不在此团队'),
  },
  Role: {
    superadmin: I18N.t('超级管理员'),
    boss: 'BOSS',
    manager: I18N.t('经理'),
    staff: I18N.t('员工'),
  },
  FunctionModules: {
    SHOP: I18N.t('分身管理'),
    IP: I18N.t('IP管理'),
    EXPENSE: I18N.t('费用管理'),
    OPERATE_LOG: I18N.t('操作日志'),
    TEAM: I18N.t('团队管理'),
    RPA: I18N.t('自动化'),
    EXTENSION: I18N.t('插件'),
    DISK: I18N.t('云盘'),
    TK: I18N.t('TK店铺自动邀约系统'),
    KOL: I18N.t('KOL私域管理'),
    MOBILE: I18N.t('手机管理'),
  },
  AccountCategories: {
    SocialMedia: I18N.t('社交媒体'),
    Shop: I18N.t('电商平台'),
    Payment: I18N.t('支付平台'),
    Other: I18N.t('其他类别'),
  },
  ...ShopConstans,
};
