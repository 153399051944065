// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 获取国家（地区）列表 GET /api/meta/countries */
export async function metaCountriesGet(
  params: {
    // query
    /** 是否按照show字段过滤 */
    show?: boolean;
    /** 是否按名称模糊匹配过滤 */
    name?: string;
    /** includeLocation */
    includeLocation?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListCountryVo>('/api/meta/countries', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取所有语言 GET /api/meta/locales */
export async function metaLocalesGet(
  params: {
    // query
    /** countryCode */
    countryCode?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListLocaleVo>('/api/meta/locales', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取所有时区 GET /api/meta/timezones */
export async function metaTimezonesGet(options?: { [key: string]: any }) {
  return request<API.WebResultListTimezoneVo>('/api/meta/timezones', {
    method: 'GET',
    ...(options || {}),
  });
}
