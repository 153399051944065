import I18N from '@/i18n';
import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { Button, message, notification, Typography } from 'antd';
import type { RequestConfig, RunTimeLayoutConfig } from 'umi';
import type { RequestOptionsInit, ResponseError } from 'umi-request';
import _ from 'lodash';
import classNames from 'classnames';
import querystring from 'querystring';
import { accountRefreshTokenGet as refreshToken } from '@/services/api-Account/LoginController';
import { accountDeviceHeartbeatGet } from '@/services/api-Account/LoginDeviceController';
import {
  buildAppVersion,
  errorMessageI18n,
  forgetMyTeam,
  getApiUrl,
  getJwt,
  getTeamIdFromUrl,
  isPublicPage,
  setJwt,
  SkipErrorNotifyOption,
} from '@/utils/utils';
import {
  getAppUUID,
  getCpus,
  getHostName,
  getMemory,
  isElectron,
  sendAsync,
} from '@/utils/ElectronUtils';
import LeftMenu from '@/components/LeftMenu';
import ContentLayout from '@/components/ContentLayout';
import styles from './app.less';
import { fetchCurrentUser, getCurrentUser } from '@/hooks/useCurrentUser';
import { metaAppVersionConfigsGet } from '@/services/api-MetaAPI/MetaController';
import {
  isBrowserEnv,
  isOpenByShopShortcut,
  openByDefaultBrowser,
  redirectToLogin,
} from '@/utils/pageUtils';
import axios from 'axios';
import {
  MEMBER_ERROR_CODE_COLLECTION,
  STRATEGY_CODE_COLLECTION,
  TEAM_MEMBER_BLOCKED,
  TEAM_MEMBER_INVALID_DEVICE,
  USER_BLOCKED,
} from '@/constants/ErrorCode';
import { history } from 'umi';
import { dispatchClose } from '@/components/Common/Modal/DMModal';
import { GlobalAlert, onServiceAvailable, onServiceUnavailable } from '@/hooks/useSysBroadcast';
import { getVersionConfig } from '@/components/CustomService';
import { i18nInit } from '@/i18n/I18N';
import Constants from '@/constants';
import ColoursIcon from '@/components/Common/ColoursIcon';
import PageLoading from '@/components/PageLoading';
import { portalRpcClient } from '@/websocket/portalRpcClient';

const isRuntimeModePage =
  window.location.protocol === 'file:' &&
  ['#/systemPref', '#/aboutRuntime'].includes(window.location.hash);

const queryObj = querystring.parse(window.location.search?.substr(1));
if (queryObj.rmJwt && !isElectron()) {
  setJwt('');
}
if (!isBrowserEnv && !isRuntimeModePage) {
  portalRpcClient.start();
}
let autoRefreshTokenInterval: NodeJS.Timeout;
const autoRefreshToken = () => {
  if (!isBrowserEnv) {
    (async () => {
      const doRefreshToken = async () => {
        try {
          // jwt有效期是30分钟
          // 每10分钟定时更新jwt
          if (getJwt()) {
            const rs = await refreshToken();
            if (rs.success && rs.data) {
              setJwt(rs.data.jwt!);
            }
          }
        } catch (error) {
          console.error(I18N.t(error));
        }
      };
      if (!autoRefreshTokenInterval) {
        autoRefreshTokenInterval = setInterval(async () => {
          await doRefreshToken();
        }, 10 * 60 * 1000);
        await doRefreshToken();
      }
    })();
  }
};

let deviceHeartbeatTimer: any = 0;
// 设备心跳
const deviceHeartbeat = () => {
  if (isBrowserEnv) return;
  clearInterval(deviceHeartbeatTimer);
  deviceHeartbeatTimer = setInterval(() => {
    accountDeviceHeartbeatGet(SkipErrorNotifyOption);
  }, 60 * 1000);
  accountDeviceHeartbeatGet(SkipErrorNotifyOption);
};

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  versionConfig?: {
    minBuildNo: number;
    currentBuildNo: number;
    earlyTryVersion: number;
    appVersion: string;
  };
  appPlatformCode?: 'Win10' | 'Win7' | 'Mac' | 'Ubuntu' | string;
  currentUser?: API.UserDetailVo | void;
  teamId?: number | undefined;
  fetchUserInfo?: () => Promise<API.UserDetailVo | undefined>;
}> {
  if (isBrowserEnv || isRuntimeModePage || isOpenByShopShortcut()) {
    return {};
  }
  const fetchUserInfo = async () => {
    try {
      console.log('调试代码，获取用户信息');
      return await fetchCurrentUser();
    } catch (error) {
      localStorage.removeItem('hideEvnTipsBar');
      // 如果是登录页面，不执行
      if (!isPublicPage()) {
        forgetMyTeam();
        redirectToLogin();
      }
    }
    return undefined;
  };

  const versionConfig = {
    minBuildNo: 0,
    currentBuildNo: 0,
    earlyTryVersion: 0,
    appVersion: '',
  };
  let appPlatformCode = '';
  if (isElectron()) {
    // 获取 app 版本信息
    try {
      appPlatformCode = await new Promise((resolve) => {
        sendAsync('get-app-platform').then((code) => resolve(code));
        setTimeout(() => resolve(''), 100);
      });
      const { data: versionConfigsData = {} } = await metaAppVersionConfigsGet();
      const { versionConfigs } = versionConfigsData;
      if (versionConfigs) {
        const config = getVersionConfig(versionConfigs, appPlatformCode);
        if (config) {
          versionConfig.minBuildNo = config.minBuildNo!;
          versionConfig.currentBuildNo = config.currentBuildNo!;
          versionConfig.earlyTryVersion = config.earlyTryVersion!;
          versionConfig.appVersion = buildAppVersion(config.appVersion!, config.earlyTryVersion);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
  const currentUser = await fetchUserInfo();
  let teamId;
  try {
    teamId = getTeamIdFromUrl();
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return {
    versionConfig,
    appPlatformCode,
    fetchUserInfo,
    currentUser,
    teamId,
    settings: {},
  };
}

// 自动将storage中的jwt带到header中
const authHeaderInterceptor = (url: string, options: RequestOptionsInit) => {
  const jwt: string = getJwt();
  // 这里不能直接读取options.headers.ContentType 应该由浏览器自己生成,防止formData 没有boundary头
  const headers = { Authorization: jwt };
  if (options?.headers?.['x-dm-team-id']) {
    headers['x-dm-team-id'] = options.headers['x-dm-team-id'];
  }
  if (isOpenByShopShortcut()) {
    headers.Authorization = '';
  }
  return {
    url,
    options: {
      ...options,
      interceptors: true,
      headers,
      credentials: 'include',
    },
  };
};

// 自动将当前url中的teamId带到header中
const teamIdHeaderInterceptor = (url: string, options: RequestOptionsInit) => {
  let teamId;
  try {
    teamId = getTeamIdFromUrl();
    // eslint-disable-next-line no-empty
  } catch (e) {}
  let headers = {};
  if (options.headers) {
    headers = { ...options.headers };
  }
  if (teamId && !headers['x-dm-team-id']) {
    headers['x-dm-team-id'] = teamId;
  }
  if (window.location.search) {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('sscToken')) {
      headers['x-ssc-token'] = searchParams.get('sscToken');
    }
  }
  return {
    url,
    options: { ...options, interceptors: true, headers },
  };
};
const acceptLanguage = (url: string, options: RequestOptionsInit) => {
  let headers = {};
  if (options.headers) {
    headers = { ...options.headers };
  }
  headers['Accept-Language'] = I18N.getLocale();
  return {
    url,
    options: { ...options, interceptors: true, headers },
  };
};

// 处理网络异常
const responseStatusInterceptor = (response: Response) => {
  const key = 'global_network_error';
  if (!response.status || response.status >= 500) {
    if (!GlobalAlert.isActive()) {
      message.error({
        content: I18N.t('您的网络发生异常，无法连接服务器'),
        duration: 0,
        key,
      });
      onServiceUnavailable();
    }
  } else {
    if (!response.url.includes('ajax-event/query')) {
      onServiceAvailable();
    }
    message.destroy(key);
  }
  return response;
};

// 处理用户状态异常
const onMemberStatusNotValid = async (response: Response) => {
  if (response.status === 200) {
    const data = await response.clone().json();
    if (
      MEMBER_ERROR_CODE_COLLECTION.includes(data.code) &&
      !response.url.includes('ajax-event/query')
    ) {
      const { code, message } = data;

      if (!GlobalAlert.isActive()) {
        if (code === USER_BLOCKED) {
          // 用户被系统禁用
          GlobalAlert.alert({
            type: 'info',
            icon: false,
            width: 600,
            closable: false,
            visible: true,
            title: (
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: 32,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 24,
                }}
              >
                <span>
                  <ColoursIcon size={100} className={'zhanghaomima_24'} />
                </span>
                <div>
                  <Typography.Text style={{ fontSize: 24 }} type={'danger'}>
                    {I18N.t('您的账号已经被冻结')}
                  </Typography.Text>
                </div>
                <div>
                  {I18N.t('出于某些安全原因，您的账号已被冻结，无法执行任何操作，请和在线客服联络')}
                </div>
              </div>
            ),
            okButtonProps: {
              hidden: true,
            },
            footerBtns: [
              {
                content: (
                  <Button
                    type={'primary'}
                    onClick={() => {
                      openByDefaultBrowser(Constants.productSite + '/service/about');
                    }}
                  >
                    {I18N.t('在线客服')}
                  </Button>
                ),
              },
            ],
          });
        } else if (code === TEAM_MEMBER_INVALID_DEVICE) {
          // 不在白名单内
          GlobalAlert.alert({
            type: 'info',
            icon: false,
            width: 600,
            closable: false,
            visible: true,
            title: (
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: 32,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 24,
                }}
              >
                <span>
                  <ColoursIcon size={100} className={'zhanghaomima_24'} />
                </span>
                <div>
                  <Typography.Text style={{ fontSize: 18 }}>
                    {I18N.t('登录设备白名单限制您进入此团队')}
                  </Typography.Text>
                </div>
                <div>{message}</div>
              </div>
            ),
            okButtonProps: {
              hidden: true,
            },
            footerBtns: [
              {
                content: (
                  <Button
                    type={'primary'}
                    onClick={() => {
                      history.push('/selectTeam');
                    }}
                  >
                    {I18N.t('切换团队')}
                  </Button>
                ),
              },
            ],
          });
        } else {
          const title =
            code === TEAM_MEMBER_BLOCKED
              ? I18N.t('您在当前团队中被禁用')
              : I18N.t('您已从当前团队中被踢出');
          // 用户在团队内被禁用
          GlobalAlert.alert({
            type: 'info',
            title,
            content: I18N.t(
              '请联络当前团队管理员，以获取进一步的信息，我们将为您跳转到团队选择页面',
            ),
            onOk() {
              GlobalAlert.destroy();
              history.push('/selectTeam');
            },
          });
        }
      }
      forgetMyTeam(getCurrentUser()?.id);
    }
  }
  return response;
};

/**
 * 异常处理程序
 const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    405: '请求方法不被允许。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
 };
 * @see https://beta-pro.ant.design/docs/request-cn
 */
export const request: RequestConfig = {
  prefix: getApiUrl().substr(0, getApiUrl().length - 1),
  errorConfig: {
    adaptor: (resData) => {
      return {
        ...resData,
        errorMessage: resData.message,
        errorCode: resData.code,
      };
    },
  },
  errorHandler: (error: ResponseError) => {
    const { response } = error;
    if (response && response.code) {
      if (!isBrowserEnv && response.code === 401) {
        setJwt('');
        if (!isPublicPage()) {
          redirectToLogin();
        }
        return;
      }
    }
    if (response && response.status) {
      const { status, url, statusText } = response;
      if (!isBrowserEnv && status === 401) {
        setJwt('');
        if (!isPublicPage()) {
          redirectToLogin();
        }
        return;
      }
      if (status < 500) {
        const requestErrorMessage = I18N.t('请求失败');
        const errorMessage = `${requestErrorMessage} ${status}: ${url.split('?')[0]}`;
        notification.error({
          message: errorMessage,
          description: statusText,
        });
      }
    } else if (error.name === 'BizError') {
      if (
        !MEMBER_ERROR_CODE_COLLECTION.includes(error?.data?.code) &&
        !STRATEGY_CODE_COLLECTION.includes(error?.data?.code)
      ) {
        message.error(errorMessageI18n(error.message));
      }
    }

    throw error;
  },
  // 新增自动添加AccessToken的请求前拦截器
  requestInterceptors: [authHeaderInterceptor, teamIdHeaderInterceptor, acceptLanguage],
  responseInterceptors: [responseStatusInterceptor, onMemberStatusNotValid],
};

/**
 * 轨迹记录防抖
 */
const tgAccessFn = _.debounce((loc) => {
  try {
    let prefix = getApiUrl();
    const isProduct = window.location.hostname === 'app.szdamai.com';
    if (isProduct) {
      // 官网部署
      prefix = `https://api.szdamai.com/`;
    }
    if (!isBrowserEnv) {
      axios.post(
        `${prefix}api/webhook/tg/access`,
        {},
        {
          params: {
            accessUrl: window.location.href,
            refUrl: document.referrer,
            module: loc?.pathname,
            _wht: 'TG_3Ugqcz1es56zPSmYS7ECf',
            appId: getAppUUID(),
            hostName: getHostName(),
            cpus: getCpus(),
            mem: getMemory(),
          },
          withCredentials: true,
        },
      );
    }
  } catch (e) {
    console.log(e, 'onPageChange');
  }
}, 1000);

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = async ({ initialState }) => {
  i18nInit();
  if (isRuntimeModePage || isOpenByShopShortcut()) return {};
  autoRefreshToken();
  deviceHeartbeat();
  return {
    menu: {
      // 关闭菜单国际化
    },
    menuRender: (props, defaultDom) => {
      return <LeftMenu matchMenuKeys={defaultDom.props.matchMenuKeys} />;
    },
    collapsed: true,
    collapsedButtonRender: false,
    headerHeight: 0,
    headerRender: false,
    disableContentMargin: false,
    onPageChange: (loc) => {
      if (isBrowserEnv) {
        return;
      }
      tgAccessFn(loc);
      dispatchClose();
      // 如果没有登录，重定向到 login
      if (!getCurrentUser() && !isPublicPage()) {
        forgetMyTeam();
        redirectToLogin();
      }
    },
    navTheme: 'dark',
    title: I18N.t('花漾灵动'),
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    ...initialState?.settings,
    layout: 'side',
    className: classNames(styles.layout, { 'has-global-tips-bar': !isElectron() }),
    childrenRender: (dom) => {
      return <ContentLayout>{dom}</ContentLayout>;
    },
    request: {
      errorConfig: {
        adaptor: (resData) => {
          return {
            ...resData,
            errorMessage: resData.message,
            errorCode: resData.code,
          };
        },
      },
    },
  };
};
export const locale = {
  getLocale() {
    return I18N.getLocale();
  },
};
