import I18N from '@/i18n';
import { useEffect, useRef } from 'react';
import type { ModalFuncProps } from 'antd';
import { message } from 'antd';
import Marquee from 'react-fast-marquee';
import styles from '@/app.less';
import color from '@/style/color.less';
import IconFontIcon from '@/components/Common/IconFontIcon';
import ajaxEventClient from '@/utils/AjaxEventClient';
import { getJwt, getPortalUrl } from '@/utils/utils';
import { useLocation } from 'umi';
import { msgCenterBroadcastsGet } from '@/services/api-MessageCenterAPI/MessageCenterController';
import _ from 'lodash';
import type { Options } from '@/components/Common/DMConfirm';
import DMConfirm from '@/components/Common/DMConfirm';

let GlobalConfirm:
  | {
      destroy: any;
      update?: (
        configUpdate: ModalFuncProps | ((prevConfig: ModalFuncProps) => ModalFuncProps),
      ) => void;
    }
  | undefined;
export class GlobalAlert {
  static isActive() {
    return !!GlobalConfirm;
  }
  static alert = (config: Options) => {
    if (GlobalConfirm && GlobalConfirm.update) {
      GlobalAlert.update(config);
    } else {
      GlobalConfirm = DMConfirm(config);
    }
  };
  static destroy = () => {
    if (GlobalConfirm && GlobalConfirm.destroy) {
      GlobalConfirm.destroy();
    }
    GlobalConfirm = undefined;
  };
  static update = (configUpdate: Options) => {
    if (GlobalConfirm && GlobalConfirm.update) {
      GlobalConfirm.update(configUpdate);
    }
  };
}
const fontColorMap = {
  info: color.infoColor,
  warning: color.warningColor,
  error: color.errorColor,
};

let hanging = false;
let open = false;
let timer: NodeJS.Timeout;

/**
 * 关闭系统广播
 */
export function destroyAlert() {
  if (open) {
    message.destroy('sysBroadcastTips');
    open = false;
  }
}

/**
 * 系统状态恢复
 */
export function onServiceAvailable() {
  clearTimeout(timer);
  if (hanging) {
    destroyAlert();
  }
  hanging = false;
}

function onResponse(res: API.BroadcastMsgVo[]) {
  if (res && res?.length === 0) {
    // 系统广播关闭了
    destroyAlert();
  } else {
    const broadcastVo = res[0];
    const { msgLevel, content } = broadcastVo;
    showAlert(msgLevel, content);
  }
}

function _getSysBroadcast() {
  if (GlobalAlert.isActive()) {
    return;
  }
  msgCenterBroadcastsGet()
    .then((res) => {
      onResponse(res.data ?? []);
      if (hanging) {
        onServiceAvailable();
        window.location.reload();
      }
    })
    .catch(() => {
      hanging = true;
      // 接口报错了，说明可能API服务都挂了，认为系统正在维护
      onResponse([
        {
          msgLevel: 'Error',
          content: I18N.t('无法连接到服务器，请检查您的网络是否通畅，是否开启了全局代理。'),
        },
      ]);
      timer = setTimeout(() => {
        _getSysBroadcast();
      }, 10 * 1000);
    });
}

/**
 * 节流系统广播fn
 */
const getSysBroadcast = _.debounce(_getSysBroadcast, 2000);

/**
 * 系统异常
 */
export function onServiceUnavailable() {
  if (!hanging) {
    hanging = true;
    getSysBroadcast();
  }
}

/**
 * 显示系统广播
 * @param level
 * @param content
 * @param title
 */
function showAlert(level?: string, content?: string, title = I18N.t('系统维护')) {
  let alertTitle = title;
  if (content) {
    // 没有content 暂不处理
    let type: 'info' | 'warning' | 'error';
    if (level === 'Error') {
      type = 'error';
      alertTitle = I18N.t('错误');
    } else if (level === 'Warn') {
      type = 'warning';
    } else {
      type = 'info';
    }
    open = true;
    message[type]({
      key: 'sysBroadcastTips',
      content: (
        <div className={styles.sysBroadcastInner}>
          <span>{alertTitle}：</span>
          <span>
            <Marquee pauseOnHover delay={3} gradient={false}>
              <span style={{ marginRight: 50 }}>{content}</span>
            </Marquee>
          </span>
          <IconFontIcon
            iconName="guanbi_24"
            style={{ marginLeft: 20, cursor: 'pointer' }}
            onClick={destroyAlert}
          />
        </div>
      ),

      duration: 0,
      className: styles.sysBroadcastTips,
      style: { color: fontColorMap[type] },
    });
  }
}
function fetchResource() {
  // APP 下先尝试请求门户静态资源
  const img = new Image();
  img.src = getPortalUrl(`/logo-16.png?t=${new Date().getTime()}`);
  img.addEventListener('load', () => {
    getSysBroadcast();
  });
  img.addEventListener('error', () => {
    // 静态资源加载失败，则有可能是客户端到门户网络不同
    showAlert('Error', I18N.t('无法连接服务器，请检查您的网络是否通畅。'), I18N.t('网络错误'));
  });
}

/**
 * 系统广播
 */
export default function useSysBroadcast() {
  const { pathname } = useLocation();
  const hd = useRef<string>();
  useEffect(() => {
    const _fn = () => {
      // 浏览器首页环境
      if (process.env.UMI_ENV === 'browser') return;
      // 没有jwt会鉴权失败
      if (!getJwt()) return;
      ajaxEventClient
        .on('msg-center-broadcast-change', 0, () => {
          getSysBroadcast();
        })
        .then((id) => {
          hd.current = id;
        });
      if (process.env.UMI_ENV === 'client') {
        // APP 下先尝试请求门户静态资源
        fetchResource();
      } else {
        getSysBroadcast();
      }
    };
    _fn();
    return () => {
      if (hd.current) {
        ajaxEventClient.un(hd.current);
      }
    };
  }, []);
  useEffect(() => {
    if (process.env.UMI_ENV === 'browser') return;
    getSysBroadcast();
  }, [pathname]);
}
