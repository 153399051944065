import I18N from '@/i18n';
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
  Popover,
  List,
  Skeleton,
  Spin,
  Space,
  Card,
  Badge,
  Button,
  Input,
  Typography,
  Table,
  Tooltip,
} from 'antd';
import _ from 'lodash';
import TeamAvatar, { TeamNamePlate } from '@/components/Common/TeamAvatar';
import { SkipErrorNotifyOption } from '@/utils/utils';
import ajaxEventClient from '@/utils/AjaxEventClient';
import {
  teamByTeamIdFavoritePut,
  teamInviteInfoByInviteCodeGet as inviteInfoUseGet,
  teamJoinListGet as joinList,
  teamJoinPost as join,
  teamLoginTeamByTeamIdPut,
} from '@/services/api-Account/TeamController';
import IconFontIcon from '@/components/Common/IconFontIcon';
import CreateTeamModal from './CreateTeamModal';
import styles from './index.less';
import constants from '@/constants';
import { msgCenterUnreadCountGet } from '@/services/api-MessageCenterAPI/MessageCenterController';
import CopyableText from '../Common/CopyableText';
import useCurrentTeam from '@/hooks/useCurrentTeam';
import SwitchTeamLoading from '../Common/SwitchTeamLoading';
import classNames from 'classnames';
import { useThrottleFn } from '@umijs/hooks';
import DMModal from '@/components/Common/Modal/DMModal';
import LabelRow, { LabelRowContext } from '@/components/Common/LabelRow';
import InfoTip from '@/components/Tips/InfoTip';
import { GhostModalCaller } from '@/mixins/modal';
import DMConfirm from '@/components/Common/DMConfirm';
import UserAvatarAndName from '@/components/Common/UserAvatarAndName';

import ColoursIcon from '@/components/Common/ColoursIcon';
import HelpLink from '@/components/HelpLink';
import { useRequest } from '@@/plugin-request/request';
import { useModel } from '@@/plugin-model/useModel';

export const JoinTeam = (props: { enterTeam: (team: { name: string; id: number }) => void }) => {
  const { enterTeam } = props;
  const [visible, changeVisible] = useState(true);
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>();
  const errorText = useRef(I18N.t('非法的邀请链接，请检查邀请链接并重新输入'));
  const inviteCode = useMemo(() => {
    try {
      // eslint-disable-next-line no-new
      new URL(value);
      return _.trim(value.split('?')[0].split('/').pop());
    } catch (e) {
      setError(errorText.current);
      return '';
    }
  }, [value]);
  const [loading, setLoading] = useState<boolean>();
  const {
    data,
    loading: resLoading,
    error: resError,
    run: fetch,
  } = useRequest(
    () => {
      return inviteInfoUseGet({ inviteCode }, SkipErrorNotifyOption);
    },
    {
      manual: true,
    },
  );
  useEffect(() => {
    if (data) {
      setError('');
    } else {
      setError(resError?.message);
    }
  }, [resError, data]);
  useEffect(() => {
    setLoading(resLoading);
  }, [resLoading]);
  useEffect(() => {
    if (inviteCode) {
      fetch();
    }
  }, [fetch, inviteCode]);

  const status = useMemo(() => {
    if (loading && !data) {
      return (
        <Space>
          <IconFontIcon iconName={'loading_24'} spin />
          <span>{I18N.t('正在为您验证邀请链接的合法性...')}</span>
        </Space>
      );
    }
    if (error) {
      return <InfoTip message={error} type="error" />;
    }
    if (data) {
      return <InfoTip message={I18N.t('合法的邀请链接，点击确定马上加入吧')} type="success" />;
    }
    return <InfoTip message={I18N.t('请填写邀请链接')} type="info" />;
  }, [data, error, loading]);
  const disabled = useMemo(
    () => loading || !!error || !value || !data,
    [data, error, loading, value],
  );
  const submit = useCallback(async () => {
    if (!disabled && data) {
      try {
        const { teamName, teamId } = data!;
        setLoading(true);
        const { data: res } = await join({ teamId, inviteCode });
        if (res) {
          const name = teamName;
          if (res.joinTeamStatus === 'JOIN_SUCCESS') {
            DMConfirm({
              iconType: 'success',
              title: I18N.t('您已成功加入“{{name}}”', {
                name,
              }),
              okText: I18N.t('进入团队'),
              content: I18N.t('恭喜，您已成功加入团队“{{name}}”', {
                name,
              }),
              onOk() {
                enterTeam({
                  id: teamId!,
                  name: teamName!,
                });
              },
            });
          } else if (res.joinTeamStatus === 'ALREADY_IN_TEAM') {
            DMConfirm({
              width: 600,
              title: I18N.t('您已经在“{{name}}”团队中了', {
                name,
              }),
              content: I18N.t('您已经是“{{name}}”团队的成员，无需重复申请', {
                name,
              }),
              type: 'info',
            });
          } else if (res.joinTeamStatus === 'ALREADY_IN_AUDIT') {
            DMConfirm({
              type: 'info',
              width: 600,
              title: I18N.t('您已经申请加入“{{name}}”团队', {
                name,
              }),
              content: I18N.t('您申请加入“{{name}}”团队正在等待团队管理员的审核，无需重复申请', {
                name,
              }),
            });
          } else if (res.joinTeamStatus === 'WAIT_AUDIT') {
            DMConfirm({
              type: 'info',
              width: 600,
              title: I18N.t('加入“{{name}}”团队的申请已发出', {
                name,
              }),
              content: I18N.t(
                '您的团队加入申请已提交，正在等待审核，如果您绑定了手机，审核通过后将得到一条短信通知，请留意查收',
              ),
            });
          }
        }

        changeVisible(false);
        setLoading(false);
      } catch (e) {
        changeVisible(false);
        setLoading(false);
      }
    }
  }, [data, disabled, enterTeam, inviteCode]);
  const result = useMemo(() => {
    if (data) {
      return (
        <LabelRowContext.Provider
          value={{
            labelWidth: 90,
            labelMuted: false,
          }}
        >
          <LabelRow label={I18N.t('团队创建者')} style={{ marginBottom: '24px' }}>
            <UserAvatarAndName user={data.creator!} />
          </LabelRow>
          <LabelRow label={I18N.t('团队名称')}>
            <TeamNamePlate size={24} team={{ id: data.teamId, name: data.teamName }} />
          </LabelRow>
        </LabelRowContext.Provider>
      );
    }
    return null;
  }, [data]);
  return (
    <DMModal
      width={640}
      okButtonProps={{
        disabled,
      }}
      onOk={submit}
      okText={!data ? I18N.t('确定') : I18N.t('立即加入')}
      title={I18N.t('加入团队')}
      visible={visible}
      onCancel={() => {
        changeVisible(false);
      }}
    >
      {!data ? (
        <LabelRow label={I18N.t('邀请链接')} labelMuted={false}>
          <Space direction={'vertical'} style={{ flex: 1 }}>
            <Input
              autoFocus
              readOnly={loading}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onPressEnter={submit}
            />

            {status}
          </Space>
        </LabelRow>
      ) : (
        result
      )}
    </DMModal>
  );
};

const SwitchTeam: React.FC = () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const {
    data,
    error,
    loading,
    run: loadTeamList,
  } = useRequest(
    () =>
      joinList({ status: 'Ready' }).then((res) => {
        return {
          data: res.data!,
        };
      }),
    {},
  );
  const { team: currentTeam } = useCurrentTeam();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [showEnterTeam, setShowEnterTeam] = useState<API.TeamDto | null>();
  const [newMsgAnimation, setNewMsgAnimation] = useState(false);
  const { joinTeam, setJoinTeam } = useModel('actionTodo');
  const { run: switchFavorite, loading: ing } = useRequest(
    (favorite, teamId) => {
      return teamByTeamIdFavoritePut({
        favorite,
        teamId,
      });
    },
    {
      manual: true,
      onSuccess() {
        loadTeamList();
      },
    },
  );

  const openCreateTeamModal = useCallback(() => {
    GhostModalCaller(
      <CreateTeamModal
        onUpdate={(team) => {
          setInitialState({ ...initialState, teamId: team.id });
          setShowEnterTeam(team);
          teamLoginTeamByTeamIdPut({ teamId: team.id! });
        }}
      />,
    );
  }, [initialState, setInitialState]);
  const [userMsgUnreadCountData, setUserMsgUnreadCountData] = useState<{
    [teamId: number]: API.UserUnreadMsgCountVo[];
  }>({});
  const { run: play } = useThrottleFn(() => {
    setNewMsgAnimation(true);
    setTimeout(() => {
      setNewMsgAnimation(false);
    }, 1500);
  }, 1500);
  const getUserMsgUnreadCountData = useCallback(async () => {
    if (!currentTeam) return;
    const { data: unreadCountData } = await msgCenterUnreadCountGet({});
    let unreadMessageCount: API.UserUnreadMsgCountVo[] = unreadCountData ?? [];
    unreadMessageCount = unreadMessageCount.filter((item) => item.teamId !== currentTeam.id);
    setUserMsgUnreadCountData(_.groupBy(unreadMessageCount, 'teamId'));
  }, [currentTeam]);
  const showJoinTeamModal = useCallback(() => {
    GhostModalCaller(
      <JoinTeam
        enterTeam={(team) => {
          teamLoginTeamByTeamIdPut({ teamId: team.id! });
          setShowEnterTeam(team);
        }}
      />,
      'JoinTeam',
    );
  }, []);

  useEffect(() => {
    if (joinTeam) {
      showJoinTeamModal();
      setJoinTeam(false);
    }
  }, [joinTeam, setJoinTeam, showJoinTeamModal]);
  useEffect(() => {
    if (showEnterTeam) {
      setPopoverVisible(false);
    }
  }, [showEnterTeam]);
  useEffect(() => {
    if (currentTeam?.name) {
      if (popoverVisible) {
        loadTeamList();
      }
    }
  }, [currentTeam?.name, loadTeamList, popoverVisible]);
  useEffect(() => {
    let hd = '';
    let newMsgHd = '';
    (async () => {
      hd = await ajaxEventClient.on('user-joined-team', 'none', () => {
        loadTeamList();
      });
      newMsgHd = await ajaxEventClient.on('msg-center-new-message', 0, (content) => {
        const { teamId } = content;
        if (teamId !== currentTeam?.id) {
          play();
        }
        getUserMsgUnreadCountData();
      });
    })();
    getUserMsgUnreadCountData();
    return () => {
      if (hd) {
        ajaxEventClient.un(hd);
        ajaxEventClient.un(newMsgHd);
      }
    };
  }, [currentTeam]);

  if (!currentTeam || (error && !data)) {
    return (
      <Space align="center" style={{ height: 56, width: '100%', justifyContent: 'center' }}>
        <Spin />
      </Space>
    );
  }

  const currentTeamWithRole = data?.find((o) => o.id === currentTeam.id);
  let teamTable = (
    <Table
      scroll={{ y: 400 }}
      loading={loading}
      pagination={false}
      dataSource={data?.filter((item) => item.id !== currentTeam.id)}
      onRow={() => {
        return {
          style: {
            height: 42,
          },
        };
      }}
      columns={[
        {
          dataIndex: 'name',
          title: I18N.t('团队名称'),
          render(_text, record) {
            const { id, name } = record;
            return (
              <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                <Badge
                  dot={_.sumBy(userMsgUnreadCountData[id!], 'msgCount') > 0}
                  style={{ flex: '0 0 24px' }}
                >
                  <TeamAvatar team={record} size={24} />
                </Badge>
                <div style={{ flex: 1, overflow: 'hidden' }}>
                  <Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>
                </div>
              </div>
            );
          },
        },
        {
          dataIndex: 'id',
          title: I18N.t('团队ID'),
          width: '130px',
          ellipsis: true,
          render(_text, record) {
            const { id } = record;
            return (
              <CopyableText type={I18N.t('团队ID')} text={id?.toString()}>
                {id}
              </CopyableText>
            );
          },
        },
        {
          dataIndex: 'role',
          title: I18N.t('我的角色'),
          width: '75px',
          ellipsis: true,
          render(_text, record) {
            const { roleCode } = record;
            return (
              <Typography.Text ellipsis>
                {roleCode === 'superadmin' ? I18N.t('超管') : constants.Role[roleCode!]}
              </Typography.Text>
            );
          },
        },
        {
          dataIndex: 'option',
          title: I18N.t('操作'),
          align: 'center',
          ellipsis: true,
          width: '65px',
          render(_text, record) {
            if (record.id === currentTeam.id) {
              return (
                <Typography.Text type={'success'}>
                  <IconFontIcon iconName={'chenggong_24'} />
                </Typography.Text>
              );
            }
            return (
              <Button
                type={'primary'}
                size={'small'}
                onClick={() => {
                  setShowEnterTeam(record);
                }}
              >
                {I18N.t('切换')}
              </Button>
            );
          },
        },
        {
          title: (
            <span>
              <ColoursIcon size={16} className={'shoucang_24'} />
            </span>
          ),
          align: 'center',
          ellipsis: true,
          width: '50px',
          dataIndex: 'favor',
          render(_text, record) {
            const { favorite, id } = record;
            return (
              <Tooltip title={I18N.t('收藏后的团队会置顶，帮助您快速定位团队')}>
                <Typography.Link
                  disabled={ing}
                  onClick={() => {
                    switchFavorite(!favorite, id);
                  }}
                >
                  <IconFontIcon iconName={favorite ? 'yishoucang_24' : 'shoucang2_24'} />
                </Typography.Link>
              </Tooltip>
            );
          },
        },
      ]}
      size={'small'}
    />
  );
  if (!loading && data?.length === 1) {
    teamTable = (
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 24,
          padding: '32px',
        }}
      >
        <ColoursIcon className={'chuangjianxintuandui_24'} size={80} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 16,
            flexDirection: 'column',
            textIndent: '2em',
            color: '#878787',
          }}
        >
          <div>
            {I18N.t(
              '花漾灵动基于"阿米巴"的多团队经营管理模式，您可以创建/加入多个团队，并在不同团队之间自如切换',
            )}
          </div>
          <div>
            {I18N.t(
              '不同团队之间的资源是隔离的，但可以通过分身转让、分身联营、快捷方式等手段实现不同团队之间的资源共享与协作',
            )}
          </div>
          <HelpLink href={'/team/brief'} />
        </div>
      </div>
    );
  }

  return (
    <>
      <Popover
        placement="rightTop"
        content={
          <>
            <div className="switch-team-scroll-container">
              <Card className={styles.currentTeamWrap} bordered={false}>
                <div className="current-team-body">
                  <Skeleton avatar title={false} active loading={false}>
                    <List.Item.Meta
                      avatar={<TeamAvatar team={currentTeam} size={48} />}
                      title={<div className="team-info-name">{currentTeam.name}</div>}
                      description={
                        <div className="team-info-description">
                          <span>
                            {I18N.t('团队ID：')}

                            <CopyableText
                              type={I18N.t('团队ID')}
                              text={currentTeam.id?.toString()}
                            />
                          </span>
                          <div>
                            {I18N.t('我的角色：')}
                            <Space className="team-info-role" size={4}>
                              <IconFontIcon iconName="renwu_24" />
                              <span>
                                {currentTeamWithRole?.roleCode === 'superadmin'
                                  ? I18N.t('超管')
                                  : constants.Role[currentTeamWithRole?.roleCode || '']}
                              </span>
                            </Space>
                          </div>
                        </div>
                      }
                    />
                  </Skeleton>
                </div>
              </Card>
              <Card
                title={I18N.t('我加入的其他团队（共{{count}}个）', {
                  count: Math.max((data?.length || 0) - 1, 0),
                })}
                className={styles.teamListWrap}
                bordered={false}
              >
                <div style={{ height: 450, overflow: 'hidden' }}>{teamTable}</div>
              </Card>
            </div>
            <Button.Group className={styles.switchTeamFootBtns}>
              <Button
                type="primary"
                ghost
                block
                onClick={() => {
                  setPopoverVisible(false);
                  openCreateTeamModal();
                }}
              >
                <Space size={4}>
                  <IconFontIcon iconName="tianjia_24" />
                  <span>{I18N.t('创建新团队')}</span>
                </Space>
              </Button>
              <Button
                type={'primary'}
                ghost
                block
                onClick={() => {
                  setPopoverVisible(false);
                  showJoinTeamModal();
                }}
              >
                <Space size={4}>
                  <IconFontIcon iconName="tuandui_24" />
                  <span>{I18N.t('加入已有团队')}</span>
                </Space>
              </Button>
              <Button
                type={'primary'}
                ghost
                block
                onClick={() => {
                  loadTeamList();
                }}
              >
                <Space size={4}>
                  <IconFontIcon iconName="shuaxin_24" />
                  <span>{I18N.t('刷新团队列表')}</span>
                </Space>
              </Button>
            </Button.Group>
          </>
        }
        mouseLeaveDelay={0.8}
        trigger="hover"
        visible={popoverVisible}
        overlayClassName={styles.overlayWrap}
        overlayStyle={popoverVisible ? { pointerEvents: 'visible' } : { pointerEvents: 'none' }}
        onVisibleChange={(visible) => setPopoverVisible(visible)}
      >
        <Badge
          dot={_.size(userMsgUnreadCountData) > 0}
          offset={[-16, 13]}
          className={classNames({
            [styles.newMsgAnimation]: newMsgAnimation,
          })}
        >
          <div style={{ cursor: 'pointer' }} id="switch-team" className={styles.switchTeamTrigger}>
            <TeamAvatar team={currentTeam} />
          </div>
        </Badge>
      </Popover>
      <SwitchTeamLoading targetTeam={showEnterTeam} />
    </>
  );
};

export default SwitchTeam;
