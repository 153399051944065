import React, { useCallback, useState } from 'react';
import { GhostModalCaller, useModalCaller } from '@/mixins/modal';
import styles from '@/components/CustomService/index.less';
import I18N from '@/i18n';
import ElectronUtils, {
  getAppBuildNumber,
  getAppVersion,
  isElectron,
  sendAsync,
} from '@/utils/ElectronUtils';
import { Button, message, Modal, Space, Tooltip, Typography } from 'antd';
import DMConfirm from '@/components/Common/DMConfirm';
import { supportRequestPost } from '@/services/api-Account/SupportController';
import { showAlert } from '@/components/Common/FunctionButton';
import buttonStyles from '@/style/button.less';
import {
  openByDefaultBrowser,
  openInNewWindow,
  openOfficialSiteByAppWindow,
} from '@/utils/pageUtils';
import constants from '@/constants';
import IconFontIcon from '@/components/Common/IconFontIcon';
import ColoursIcon from '@/components/Common/ColoursIcon';
import { useRequest } from 'umi';
import { metaFaqPageGet } from '@/services/api-MetaAPI/MetaFaqController';
import _ from 'lodash';
import useCurrentRole from '@/hooks/useCurrentRole';
import { metaAppVersionConfigsGet } from '@/services/api-MetaAPI/MetaController';
import { buildAppVersion } from '@/utils/utils';
import { getVersionConfig } from '@/components/CustomService/index';

export function useCheckAppVersion() {
  return useRequest(
    async () => {
      return new Promise<void>((resolve) => {
        metaAppVersionConfigsGet()
          .then(async (versionConfigsData = {}) => {
            const appBuildNumber = getAppBuildNumber();
            const appPlatformCode: string = await new Promise((resolve) => {
              sendAsync('get-app-platform').then((code) => resolve(code));
              setTimeout(() => resolve(''), 100);
            });
            const config = getVersionConfig(
              versionConfigsData.data?.versionConfigs,
              appPlatformCode,
            );
            if (config && config.earlyTryVersion! > Number(appBuildNumber)) {
              DMConfirm({
                iconType: 'info',
                title: I18N.t('有最新版本需要更新'),
                content: `${I18N.t('有新版本')}${
                  config.appVersion
                    ? ` ${buildAppVersion(config.appVersion, config.earlyTryVersion)} `
                    : ''
                }${I18N.t('可以更新，请下载最新版本后，重新安装即可（原有数据将完整保留）')}`,

                okText: I18N.t('立即下载'),
                onOk: () => {
                  openOfficialSiteByAppWindow(constants.appDownloadLinkFromApp);
                  resolve();
                },
              });
            } else {
              DMConfirm({
                type: 'info',
                title: I18N.t('当前已经是最新版本'),
                content: I18N.t('恭喜，当前已经是最新版本，无需更新'),
                onOk() {
                  resolve();
                },
              });
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },
    {
      manual: true,
      throttleInterval: 500,
    },
  );
}
/**
 *
 * @constructor
 */
const CustomServiceModal = () => {
  const [visible, changeVisible] = useState(true);
  const onClose = useCallback(() => {
    changeVisible(false);
  }, []);
  const { isSpecialRole } = useCurrentRole();
  const releaseVersion = getAppVersion();
  const { data } = useRequest(() => metaFaqPageGet({ pageNum: 1, pageSize: 14 }), {
    formatResult(res) {
      return _.sortBy(
        _.filter(res.data?.list, (item) => !item.hide),
        'sortNo',
      );
    },
  });

  const { run: checkNewestAppVersion } = useCheckAppVersion();

  return (
    <Modal
      visible={visible}
      centered
      wrapProps={{ id: 'custom-service-modal' }}
      bodyStyle={{ padding: 0 }}
      onCancel={onClose}
      wrapClassName={styles.customService}
      width={720 + 360}
      footer={false}
      modalRender={(node) => {
        return (
          <div style={{ display: 'flex', gap: 16, overflow: 'hidden' }}>
            <div style={{ width: 720 }}>{node}</div>
            <div className={styles.serviceCommonQa} style={{ width: 360, background: 'white' }}>
              <div className="title">{I18N.t('常见问题')}</div>
              <div className="list">
                {data?.map((item, index) => {
                  const { title, url, id } = item;
                  return (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        if (isElectron()) {
                          ElectronUtils.emitEvent('open-external-url', {
                            url,
                          });
                        } else {
                          window.open(url);
                        }
                      }}
                      key={id}
                      style={{
                        marginTop: 8,
                        width: '100%',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 8,
                      }}
                    >
                      <span>{index + 1}.</span>
                      <div style={{ flex: '1', overflow: 'hidden' }} title={title}>
                        {title}
                      </div>
                    </a>
                  );
                })}
              </div>
              <div className="footer">
                <Tooltip title={I18N.t('查看花漾客户端日志文件所在位置，方便排错')}>
                  <Button
                    type={'primary'}
                    // hidden={getAppVersion() < '8.2'}
                    onClick={() => {
                      if (isElectron()) {
                        sendAsync('open-log-dir');
                      } else {
                        DMConfirm({
                          title: I18N.t('只支持在客户端中打开日志文件'),
                          content: I18N.t('请您在花漾客户端中使用本功能'),
                        });
                      }
                    }}
                  >
                    {I18N.t(`日志文件`)}
                  </Button>
                </Tooltip>

                <Button
                  type={'primary'}
                  danger
                  onClick={() => {
                    if (isSpecialRole) {
                      let _time = 10;
                      const _confirm = DMConfirm({
                        width: 660,
                        title: I18N.t('将花漾官方技术支持添加至您的团队'),
                        content: (
                          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                            <div>{I18N.t(`1）请确认您已经和花漾技术支持团队建立了联系`)}</div>
                            <div>
                              {I18N.t(
                                `2）点击确定后，名称为“花漾官方技术支持”的账号会临时进入您的团队，并承担“BOSS”角色`,
                              )}
                            </div>
                            <div>
                              {I18N.t(
                                `3）花漾技术支持团队会通过上述账号，在您同意并授权的情况下，操作您的资源，完成问题的排查、定位与解决`,
                              )}
                            </div>
                            <div>
                              {I18N.t(
                                `4）6小时后，该账号会自动退出您的团队，在此之前，您也可以手动强制踢出此账号`,
                              )}
                            </div>
                            <div>{I18N.t(`请确认您已经阅读并理解了上述内容`)}</div>
                          </div>
                        ),
                        okButtonProps: {
                          disabled: true,
                        },
                        okText: `${I18N.t('确定') + '（'}${_time}s）`,
                      });
                      const _fn = () =>
                        setTimeout(() => {
                          _time--;
                          _confirm.update({
                            okButtonProps: {
                              disabled: _time > 0,
                            },
                            onOk:
                              _time === 0
                                ? () => {
                                    supportRequestPost({
                                      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                      lang: I18N.getLocale(),
                                      clientTimestamp: new Date().getTime(),
                                    }).then(() => {
                                      message.success(I18N.t('已经发起申请'));
                                    });
                                  }
                                : undefined,
                            okText:
                              _time > 0 ? `${I18N.t('确定') + '（'}${_time}s）` : I18N.t('确定'),
                          });
                          if (_time > 0) {
                            _fn();
                          }
                        }, 1000);
                      _fn();
                    } else {
                      showAlert();
                    }
                  }}
                >
                  {I18N.t('远程协助')}
                </Button>
                <Button
                  className={buttonStyles.successBtn}
                  onClick={() => {
                    openByDefaultBrowser(constants.productSite + '/help/faqs');
                  }}
                >
                  {I18N.t('更多问题')}
                </Button>
              </div>
            </div>
          </div>
        );
      }}
    >
      <div className="custom-service-head">
        <div className="custom-service-head-title">{I18N.t('在线客服')}</div>
        <div className="custom-service-head-description">
          {I18N.t('有任何关于技术支持、商务、合作等各类需求请与我们的在线客服进行联系')}
        </div>
        <div className="custom-service-head-banner" />
      </div>
      <div className="custom-service-body">
        <div className="custom-service-body-left">
          <Space className="custom-service-wechat-title">
            <IconFontIcon iconName="weixin_24" />
            {I18N.t('微信客服')}
          </Space>
          <div className="custom-service-wechat-description">
            {I18N.t('在线时间，08:00-24:00')}

            <br />
            {I18N.t('其余时间可留言')}
          </div>
          <div className="custom-service-wechat-qrcode" />
          <Space style={{ margin: '8px 0' }}>
            <ColoursIcon className={'zhichi_24'} />
            <Typography.Link
              onClick={() => {
                openOfficialSiteByAppWindow('/help');
              }}
            >
              {I18N.t('帮助文档')}
            </Typography.Link>
          </Space>
          <Space>
            <ColoursIcon className={'duanshipin_24'} />
            <Typography.Link
              onClick={() => {
                openByDefaultBrowser(constants.productSite + '/service/course/bootstrap');
              }}
            >
              {I18N.t('视频教程')}
            </Typography.Link>
          </Space>
        </div>
        <div className="custom-service-body-right">
          <div className="custom-service-item">
            <div className="custom-service-item-title">{I18N.t('在线客服')}</div>
            <div className="custom-service-item-sub">
              <div className="custom-service-item-sub-label">{I18N.t('产品技术支持')}</div>
              <div className="custom-service-item-sub-colon">：</div>
              <div className="custom-service-item-sub-description">（08:00-20:00）</div>
              <Space
                className="custom-service-item-sub-value custom-service-item-sub-link"
                onClick={() => openInNewWindow(constants.qdSupportLink)}
              >
                <IconFontIcon iconName="kefu_24" />
                {I18N.t('技术支持客服')}
              </Space>
            </div>
            <div className="custom-service-item-sub">
              <div className="custom-service-item-sub-label">{I18N.t('商务合作')}</div>
              <div className="custom-service-item-sub-colon">：</div>
              <div className="custom-service-item-sub-description">（08:00-20:00）</div>
              <Space
                className="custom-service-item-sub-value custom-service-item-sub-link"
                onClick={() => openInNewWindow(constants.qdBusinessLink)}
              >
                <IconFontIcon iconName="kefu_24" />
                {I18N.t('商务合作洽谈')}
              </Space>
            </div>
          </div>
          <div className="custom-service-item">
            <div className="custom-service-item-title">{I18N.t('热线电话')}</div>
            <div className="custom-service-item-sub">
              <div className="custom-service-item-sub-label">{I18N.t('热线电话')}</div>
              <div className="custom-service-item-sub-colon">：</div>
              <div className="custom-service-item-sub-description">（08:00-24:00）</div>
              <Space className="custom-service-item-sub-value">
                <IconFontIcon iconName="dianhua_24" />
                {constants.hotline}
              </Space>
            </div>
          </div>
          <div className="custom-service-item">
            <div className="custom-service-item-title">{I18N.t('邮件咨询')}</div>
            <div className="custom-service-item-sub">
              <div className="custom-service-item-sub-label">{I18N.t('技术支持邮件')}</div>
              <div className="custom-service-item-sub-colon">：</div>
              <div className="custom-service-item-sub-description">{I18N.t('（2小时内响应）')}</div>
              <Space className="custom-service-item-sub-value">
                <IconFontIcon iconName="xiaoxizhongxin_24" />
                support@szdamai.com
              </Space>
            </div>
          </div>
        </div>
      </div>
      <div className="app-version">
        {isElectron() && (
          <>
            {I18N.t('版本：')}
            {releaseVersion}
            <a style={{ marginLeft: 16 }} onClick={checkNewestAppVersion}>
              {I18N.t('检查更新')}
            </a>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CustomServiceModal;

export function useCustomServiceModal() {
  return useCallback(() => {
    GhostModalCaller(<CustomServiceModal />, 'CustomServiceModal');
  }, []);
}
