import I18N from '@/i18n';
import LabelRow, { LabelRowContext } from '@/components/Common/LabelRow';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Space, Tooltip, Typography } from 'antd';
import type { ActionType } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { scrollProTableOptionFn } from '@/mixins/table';
import styles from './../userSetting.less';
import {
  accountDeviceByDeviceIdLogoutDelete,
  accountDeviceRemainLoginPut,
  accountDevicesGet,
} from '@/services/api-Account/LoginDeviceController';
import DMConfirm from '@/components/Common/DMConfirm';
import { dateFormat } from '@/utils/utils';
import ColoursIcon from '@/components/Common/ColoursIcon';
import Placeholder from '@/components/Common/Placeholder';
import uaParser from 'ua-parser-js';
import bytes from 'bytes';
import SortTitle from '@/components/Sort/SortTitle';
import { useOrder } from '@/components/Sort/SortDropdown';

export type DeviceFormInstance = {
  submit: () => Promise<void>;
  reset: () => void;
};

export function osIconName(osName: string | undefined) {
  if (!osName) {
    return 'kehuduan_24';
  }
  const _osName = osName.toLowerCase();
  if (_osName.includes('windows')) {
    return 'Windows_24';
  }
  if (_osName.includes('mac')) {
    return 'MacOS_24';
  }
  if (_osName.includes('linux')) {
    return 'Linux_24';
  }

  return 'kehuduan_24';
}
export const Device = (props: { data: API.UserLoginDeviceVo; iconSize?: number }) => {
  const { data, iconSize } = props;
  if (!data) {
    return <Placeholder />;
  }
  const hacks = ['Safari', 'Firefox', 'Chrome', 'Edge'];
  const { userAgent = '', hostName, deviceType, osName } = data;
  let iconName = 'Global';
  const { browser, os } = uaParser(userAgent)!;
  let name = browser?.name || os?.name;
  if (deviceType === 'App') {
    iconName = osIconName(os.name);
    name = hostName;
  } else if (osName?.includes('Android')) {
    iconName = 'Android_24';
  } else if (hacks.includes(name)) {
    iconName = name;
    if (['Chrome', 'Edge'].includes(name)) {
      iconName = String(name).toLowerCase();
    }
    iconName = `${iconName}_24`;
  }
  return (
    <div
      style={{
        gap: 5,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <ColoursIcon className={iconName} size={iconSize} />
      <div style={{ overflow: 'hidden', flex: 1 }}>
        <Typography.Text style={{ color: 'inherit' }} ellipsis title={name}>
          {name}
        </Typography.Text>
      </div>
    </div>
  );
};

const Devices = forwardRef((props: { editing: boolean }, ref) => {
  const { editing } = props;
  const { order, changeOrder } = useOrder({ key: 'lastLoginTime', ascend: false }, 'devices');
  const [appRemainLogin, setAppRemainLogin] = useState(true);
  const valueRef = useRef(appRemainLogin);
  // const fetch = useCallback(() => {
  //   accountDeviceRemainLoginGet().then((res) => {
  //     setAppRemainLogin(res.data);
  //     valueRef.current = res.data;
  //   });
  // }, []);
  // useMount(() => {
  //   fetch();
  // });
  const tableRef = useRef<ActionType>();
  useEffect(() => {
    tableRef.current?.reloadAndRest?.();
  }, [order]);
  const columns = useMemo(() => {
    return [
      {
        title: (
          <SortTitle
            order={order}
            orderKey={'hostName'}
            onSort={changeOrder}
            label={I18N.t('设备名称')}
          />
        ),

        dataIndex: 'hostName',
        render(_, record): JSX.Element {
          return <Device data={record} />;
        },
      },
      {
        title: I18N.t('设备标识'),
        dataIndex: 'deviceId',
        render(_, record: API.UserLoginDeviceVo): JSX.Element {
          const { deviceId } = record;
          if (deviceId) {
            return (
              <Typography.Text
                style={{ fontFamily: 'Consolas, Courier New, monospace' }}
                ellipsis
                title={deviceId}
              >
                {deviceId}
              </Typography.Text>
            );
          }
          return <Placeholder />;
        },
      },
      {
        title: I18N.t('设备配置'),
        width: '100px',
        dataIndex: 'disposition',
        render(_, record: API.UserLoginDeviceVo): React.ReactNode {
          const { cpus, deviceType, mem } = record;
          if (deviceType === 'App') {
            let str = `${cpus}${I18N.t('核')}`;
            if (mem) {
              str += `${bytes(mem, { fixedDecimals: true, decimalPlaces: 0 })}`;
            }
            return str;
          }
          return <Placeholder />;
        },
      },
      {
        title: (
          <SortTitle
            order={order}
            orderKey={'lastActiveTime'}
            onSort={changeOrder}
            label={I18N.t('最近活跃时间')}
          />
        ),

        dataIndex: 'lastActiveTime',
        width: '130px',
        render(_, record: API.UserLoginDeviceVo) {
          return dateFormat(
            new Date(record.lastActiveTime || record.lastLoginTime),
            'MM-dd hh:mm:ss',
          );
        },
      },
      !editing && {
        title: I18N.t('操作'),
        width: '50px',
        dataIndex: 'options',
        render(_, record: API.UserLoginDeviceVo) {
          const { id, currentDevice } = record;
          if (currentDevice) {
            return <Typography.Text type={'success'}>{I18N.t('本机')}</Typography.Text>;
          }
          return (
            <Tooltip title={I18N.t('从此设备中注销（暨解除此设备的登录态）')}>
              <Typography.Link
                type={'danger'}
                onClick={() => {
                  DMConfirm({
                    title: I18N.t('确认要退出登录此设备吗？'),
                    content: I18N.t('一旦退出登录后，下次在该设备上重新登录时需要验证您的身份'),
                    onOk() {
                      accountDeviceByDeviceIdLogoutDelete({
                        deviceId: id!,
                      }).then(() => {
                        tableRef.current?.reloadAndRest?.();
                      });
                    },
                  });
                }}
              >
                <Space>
                  <IconFontIcon iconName="tuichu_24" />
                </Space>
              </Typography.Link>
            </Tooltip>
          );
        },
      },
    ].filter(Boolean);
  }, [changeOrder, editing, order]);
  useImperativeHandle(ref, () => {
    return {
      submit() {
        return accountDeviceRemainLoginPut({
          appRemainLogin,
        }).then(fetch);
      },
      reset() {
        setAppRemainLogin(valueRef.current);
      },
    };
  });
  return (
    <div className={styles.devices}>
      <LabelRowContext.Provider value={{ labelMuted: true, labelWidth: 150 }}>
        <LabelRow label={I18N.t('已登录的客户端列表')} className="table-wrap">
          <ProTable
            actionRef={tableRef}
            columns={columns}
            request={async () => {
              const res = await accountDevicesGet({
                sortField: order.key,
                sortOrder: order.ascend ? 'asc' : 'desc',
              });
              return {
                data: res.data,
                total: res.data?.length,
              };
            }}
            {...scrollProTableOptionFn({
              pagination: false,
              size: 'small',
            })}
          />
        </LabelRow>
      </LabelRowContext.Provider>
    </div>
  );
});
export default Devices;
