import React from 'react';
import type { SwitchProps } from 'antd';
import { Switch } from 'antd';
import classNames from 'classnames';
import styles from './index.less';

const DmSwitch: React.FC<SwitchProps> = (props) => {
  const { className, ...otherProps } = props;
  return (
    <Switch
      unCheckedChildren={'OFF'}
      checkedChildren={'ON'}
      className={classNames(styles.dmSwitch, 'dm-switch', className)}
      {...otherProps}
    />
  );
};
export default DmSwitch;
