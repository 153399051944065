import I18N from '@/i18n';
import { useModel } from '@@/plugin-model/useModel';
import { ReactNode, useCallback, useRef } from 'react';
import DMConfirm, { DMLoading } from '@/components/Common/DMConfirm';
import { Checkbox, Form, Radio, Space } from 'antd';
import DMFormItem from '@/components/Common/DMFormItem';
import { Session } from '@/models/windowSync';
import { emitEvent, sendAsync } from '@/utils/ElectronUtils';
import { getTeamIdFromUrl, isWinPlatform } from '@/utils/utils';
import { GhostModalCaller } from '@/mixins/modal';
import { userCenterEmitter } from '@/components/UserCenter';
import { MainWindowSelectModal } from '@/pages/ShopManage/WindowSync';
import { getSysPresSettings } from '@/i18n/I18N';

let confirmVisible = false;

export default function useWindowSync() {
  const {
    sessions,
    setSessions,
    sessionsSorted,
    changeOrder,
    setMaster,
    setFollows,
    selectedRows,
    setControlled,
    calcSize,
    getSelectedDisplay,
  } = useModel('windowSync');
  const [confirmForm] = Form.useForm();
  const loadingModalRef = useRef<any>(null);

  const fetchGcDetail = useCallback(() => {
    if (isWinPlatform()) {
      sendAsync('gc/detail').then((detail) => {
        setControlled(detail.processing);
        setMaster(detail.master);
        setFollows(detail.follows);
      });
    }
  }, [setControlled, setFollows, setMaster]);

  const getWindowPositionOptions = useCallback(
    (layout: 'grid' | 'diagonal', _sessions: Session[] = selectedRows) => {
      const options: {
        boundsMap: Record<number, { width: number; height: number; left: number; top: number }>;
        delay: number;
        sortedShopIds: number[] | undefined;
      } = {
        boundsMap: {},
        delay: 0,
      };
      const {
        sizeMethod = 'auto',
        order = 'asc',
        columns = 3,
        left = 0,
        top = 0,
        hs = 100,
        vs = 100,
        width = 800,
        height = 600,
      } = JSON.parse(localStorage.getItem(`browser.sync.align.${layout}`) ?? '{}');
      const selectedDisplay = getSelectedDisplay(layout);
      const autoSize = calcSize(layout);
      const _width = sizeMethod === 'auto' ? autoSize.width : width;
      const _height = sizeMethod === 'auto' ? autoSize.height : height;
      let prevRight = (selectedDisplay?.workArea.x ?? 0) + left;
      let prevBottom = (selectedDisplay?.workArea.y ?? 0) + top;
      const __sessions = [..._sessions];
      if (order === 'desc') {
        __sessions.reverse();
      }
      if (layout === 'grid') {
        // 宫格布局
        __sessions.forEach((item, index) => {
          const { shop } = item;
          const _left = prevRight + (index % columns === 0 ? 0 : hs);
          const _top = prevBottom;
          if (index % columns === columns - 1) {
            //  代表下一个是每行的开始
            prevBottom = _top + _height + vs;
            prevRight = (selectedDisplay?.workArea.x ?? 0) + left;
          } else {
            prevRight = _left + _width;
          }
          options.boundsMap[shop.id!] = {
            width: _width,
            height: _height,
            left: _left,
            top: _top,
          };
        });
      } else {
        // 对角线布局
        __sessions.forEach((item, index) => {
          const { shop } = item;
          const _left = prevRight + (index === 0 ? 0 : hs);
          const _top = prevBottom + (index === 0 ? 0 : vs);
          prevRight = _left;
          prevBottom = _top;
          options.boundsMap[shop.id!] = {
            width: _width,
            height: _height,
            left: _left,
            top: _top,
          };
        });
        options.delay = 200;
      }
      options.sortedShopIds = __sessions.map((item) => item.shop.id!);
      return options;
    },
    [calcSize, getSelectedDisplay, selectedRows],
  );

  const showWindowSyncConfirm = useCallback(
    (props: {
      title: ReactNode;
      master: number;
      follows: number[];
      onFinish?: (layout: 'grid' | 'diagonal') => void;
    }) => {
      if (confirmVisible) return;
      const { title } = props;
      confirmVisible = true;
      DMConfirm({
        iconType: 'info',
        width: 550,
        title,
        content: (
          <Form
            form={confirmForm}
            initialValues={JSON.parse(
              localStorage.getItem('win-sync-pos-opt') ||
                JSON.stringify({ layout: 'grid', gridChecked: true, diagonalChecked: true }),
            )}
          >
            <DMFormItem name="layout" noStyle initialValue="grid">
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="grid">{I18N.t('宫格布局')}</Radio>
                  <DMFormItem noStyle shouldUpdate>
                    {(f) => {
                      const disabled = f.getFieldValue('layout') !== 'grid';
                      return (
                        <DMFormItem name="gridChecked" noStyle valuePropName="checked">
                          <Checkbox disabled={disabled} style={{ marginLeft: 40 }}>
                            {I18N.t('将主控窗口放到左上角第一个宫格位置')}
                          </Checkbox>
                        </DMFormItem>
                      );
                    }}
                  </DMFormItem>
                  <Radio value="diagonal">{I18N.t('对角线布局')}</Radio>
                  <DMFormItem noStyle shouldUpdate>
                    {(f) => {
                      const disabled = f.getFieldValue('layout') !== 'diagonal';
                      return (
                        <DMFormItem name="diagonalChecked" noStyle valuePropName="checked">
                          <Checkbox disabled={disabled} style={{ marginLeft: 40 }}>
                            {I18N.t('将主控窗口放到右下角最后一个窗口位置')}
                          </Checkbox>
                        </DMFormItem>
                      );
                    }}
                  </DMFormItem>
                </Space>
              </Radio.Group>
            </DMFormItem>
          </Form>
        ),

        onOk: () => {
          confirmVisible = false;
          const { layout, gridChecked, diagonalChecked } = confirmForm.getFieldsValue();
          // 保存到 localstorage
          localStorage.setItem(
            'win-sync-pos-opt',
            JSON.stringify({ layout, gridChecked, diagonalChecked }),
          );
          let _sessions = [...sessionsSorted];
          const getSortValue = (s: Session) => {
            if (s.sessionId === props.master) {
              return 1;
            }
            if (props.follows.includes(s.sessionId)) {
              return 2;
            }
            return 3;
          };
          if (layout === 'grid') {
            const { order = 'asc' } = JSON.parse(
              localStorage.getItem(`browser.sync.align.grid`) ?? '{}',
            );
            _sessions = _sessions.sort((a, b) => {
              return getSortValue(a) - getSortValue(b);
            });
            // if (order === 'desc') {
            //   _sessions = _sessions.reverse();
            // }
            if (gridChecked) {
              // 主控窗口放在第一个位置
              const master = _sessions.find((s) => s.sessionId === props.master);
              if (master) {
                _sessions = _sessions.filter((s) => s.sessionId !== props.master);
                _sessions = order === 'desc' ? [..._sessions, master] : [master, ..._sessions];
              }
            }
            changeOrder({ key: 'custom', ascend: true });
            setSessions(_sessions);
          } else if (layout === 'diagonal') {
            const { order = 'asc' } = JSON.parse(
              localStorage.getItem(`browser.sync.align.diagonal`) ?? '{}',
            );
            _sessions = _sessions.sort((a, b) => {
              return getSortValue(a) - getSortValue(b);
            });
            // if (order === 'desc') {
            //   _sessions = _sessions.reverse();
            // }
            if (diagonalChecked) {
              // 主控窗口放在最后一个位置
              const master = _sessions.find((s) => s.sessionId === props.master);
              if (master) {
                _sessions = _sessions.filter((s) => s.sessionId !== props.master);
                _sessions = order === 'desc' ? [master, ..._sessions] : [..._sessions, master];
              }
            }
            changeOrder({ key: 'custom', ascend: true });
            setSessions(_sessions);
          }
          const options = getWindowPositionOptions(layout, _sessions);
          return sendAsync('browser.sync.align', options).then(() => {
            props.onFinish?.(layout);
          });
        },
        onCancel: () => {
          confirmVisible = false;
        },
      });
    },
    [confirmForm, sessionsSorted, getWindowPositionOptions, changeOrder, setSessions],
  );

  const beforeOpen = useCallback(
    (type: string) => {
      return new Promise<void>((resolve, reject) => {
        if (!sessions?.length) {
          reject();
          return;
        }
        if (!selectedRows?.length) {
          reject();
        } else {
          resolve();
        }
      });
    },
    [selectedRows?.length, sessions?.length],
  );

  const chooseMasterWindow = useCallback(
    (onFinish?: (layout: 'grid' | 'diagonal') => void) => {
      // 选择主控窗口
      GhostModalCaller(
        <MainWindowSelectModal
          dataSource={sessionsSorted.filter((s) =>
            selectedRows.some((row) => row.sessionId === s.sessionId),
          )}
          onSelect={(item, options) => {
            const _master = item.sessionId;
            const _follows = selectedRows
              .map((_item) => _item.sessionId)
              .filter((id) => id !== item.sessionId);
            showWindowSyncConfirm({
              title: I18N.t('群控技术依赖于浏览器窗口采用相同的大小'),
              master: _master,
              follows: _follows,
              onFinish: (layout) => {
                setMaster(_master);
                setFollows(_follows);
                if (options.toolboxWindow) {
                  // 显示文本输入悬浮窗口
                  sendAsync('browser.sync.open.toolbox', { teamId: getTeamIdFromUrl() });
                }
                sendAsync('gc/start', {
                  master: _master,
                  follows: _follows,
                  ...options,
                }).then(() => {
                  fetchGcDetail();
                  if (options.syncTabs) {
                    sendAsync('gc/sync_tabs');
                  }
                  onFinish?.(layout);
                });
              },
            });
          }}
        />,
      );
    },
    [selectedRows, setMaster, setFollows, fetchGcDetail, showWindowSyncConfirm, sessionsSorted],
  );

  const downloadHelper = useCallback(() => {
    sendAsync('browser.sync.download');
  }, []);

  const startSync = useCallback(
    (onFinish?: (layout: 'grid' | 'diagonal') => void) => {
      beforeOpen(I18N.t('群控')).then(async () => {
        if (isWinPlatform()) {
          const res = await sendAsync('gc/checkExeFile');
          if (res) {
            DMConfirm({
              title:
                res === 0b11
                  ? I18N.t('群控程序需要更新，是否立即下载？')
                  : I18N.t('确定要开启群控吗？'),
              width: 600,
              content: (
                <div>
                  <div>
                    {I18N.t('1.当您点击确定后，我们将为您下载群控程序，并存储到')}

                    <a
                      onClick={() => {
                        userCenterEmitter.emit('open', 'userSetting', 'dataDir');
                      }}
                    >
                      {I18N.t('“偏好设置”')}
                    </a>
                    {I18N.t('中的')}

                    <a
                      onClick={() => {
                        const sysPresSettings = getSysPresSettings();
                        emitEvent('open-path', { path: sysPresSettings?.dataDir });
                      }}
                    >
                      {I18N.t('“浏览器配置文件”')}
                    </a>
                    {I18N.t('所在位置')}
                  </div>
                  <div style={{ marginTop: 8 }}>
                    {I18N.t(
                      '2.群控程序使用了Windows的底层技术，这往往会被杀毒软件误报为病毒，如果出现误报，请您暂时关闭杀毒软件，或者在杀毒告警中点击信任',
                    )}
                  </div>
                </div>
              ),

              okText: I18N.t('立即下载'),
              onOk: async () => {
                loadingModalRef.current = DMLoading({
                  title: I18N.t('正在下载群控程序'),
                  content: `${I18N.t('已完成0%')}`,
                });
                setTimeout(() => {
                  loadingModalRef.current?.destroy();
                }, 20 * 1000);
                await downloadHelper();
              },
            });
          } else {
            chooseMasterWindow(onFinish);
          }
        } else {
          DMConfirm({
            type: 'info',
            title: I18N.t('仅支持Windows平台的群控技术'),
            content: I18N.t('目前暂不支持MacOS与Linux平台'),
          });
        }
      });
    },
    [beforeOpen, chooseMasterWindow, downloadHelper],
  );

  return {
    fetchGcDetail,
    getWindowPositionOptions,
    showWindowSyncConfirm,
    startSync,
    beforeOpen,
    chooseMasterWindow,
    loadingModalRef,
  };
}
